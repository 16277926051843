import React, { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import Page from '../../../components/Dashboard/Page';
import { http } from '../../../utils/Utils';
import { UserContext } from '../../../store/UserContext';
import CardsHeader from '../../../components/UI/CardsHeader';
import { FaAngleDown } from 'react-icons/fa';
import {
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
  LabelList
} from 'recharts';

const DashboardReport = () => {
  const ReportContent = () => {
    const { data: userData } = useContext(UserContext);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState('2025');
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [showDetails, setShowDetails] = useState(false);

    const getGroups = async () => {
      if (!userData || !userData.client || !userData.client._id) return;
      
      try {
        const response = await http({ 
          url: `/report/groups?clientId=${userData.client._id}` 
        });
        
        if (response.groups) {
          setGroups(response.groups);
        }
      } catch (err) {
        console.error(err);
        toast.error('Errore nel caricamento dei gruppi');
      }
    };

    const getReport = async () => {
      if (!userData || !userData.client || !userData.client._id) return;
      
      setIsLoading(true);
      try {
        let url = `/report?year=${year}&clientId=${userData.client._id}`;
        if (selectedGroup) {
          url += `&groupId=${selectedGroup}`;
        }
        
        const response = await http({ url });
        
        if (response.fleetUtilization) {
          setData(response.fleetUtilization);
        }
      } catch (err) {
        console.error(err);
        toast.error(err?.reason?.error || 'Errore nel caricamento dei dati');
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (userData && userData.client && userData.client._id) {
        getGroups();
      }
    }, [userData]);

    useEffect(() => {
      if (userData && userData.client && userData.client._id) {
        getReport();
      }
    }, [year, selectedGroup, userData]);
 
    const renderExpectedLabel = (props) => {
      const { x, y, value, index, width } = props;
      const actualValue = data[index]?.actualUtilization || 0;
      
      const offset = actualValue > value ? -35 : 0;
      
      return value > 0 ? (
        <text 
          x={x + offset} 
          y={y - 15} 
          fill="#22c55e" 
          textAnchor="middle"
          fontWeight="500"
          fontSize={14}
        >
          {`${value}%`}
        </text>
      ) : null;
    };

    const renderActualLabel = (props) => {
      const { x, y, width, value } = props;
      return value > 0 ? (
        <text 
          x={x + width/2} 
          y={y - 5} 
          fill="#f97316" 
          textAnchor="middle"
          fontWeight="500"
          fontSize={14}
        >
          {`${value}%`}
        </text>
      ) : null;
    };

    const renderDataTable = () => (
      <div className="mt-8 transition-all duration-300">
        <h4 className="text-lg font-medium mb-3">Dettaglio Dati Mensili</h4>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border rounded-lg text-xs">
            <thead>
              <tr className="bg-gray-100">
                <th className="border px-2 py-1 text-left">Mese</th>
                <th className="border px-2 py-1 text-right">Utilizzo Previsto (%)</th>
                <th className="border px-2 py-1 text-right">N° Prenotazioni</th>
                <th className="border px-2 py-1 text-right">Durata Media Prenot. (gg.)</th>
                <th className="border px-2 py-1 text-right">Utilizzo Reale (%)</th>
                <th className="border px-2 py-1 text-right">N° Movo</th>
                <th className="border px-2 py-1 text-right">Durata Media Movo (gg.)</th>
                <th className="border px-2 py-1 text-right">N° Veicoli</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="border px-2 py-1">{item.name}</td>
                  <td className="border px-2 py-1 text-right">{item.expectedUtilization}%</td>
                  <td className="border px-2 py-1 text-right">{item.reservationCount}</td>
                  <td className="border px-2 py-1 text-right">{item.avgReservationDuration}</td>
                  <td className="border px-2 py-1 text-right">{item.actualUtilization}%</td>
                  <td className="border px-2 py-1 text-right">{item.rentCount}</td>
                  <td className="border px-2 py-1 text-right">{item.avgRentDuration}</td>
                  <td className="border px-2 py-1 text-right">{item.vehicleCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );

    const exportToExcel = () => {
      if (!data || data.length === 0) {
        toast.error('Nessun dato da esportare');
        return;
      }

      try {
        const groupName = selectedGroup 
          ? (() => {
              const group = groups.find(g => g._id === selectedGroup);
              return group ? `${group.mnemonic} ${group.description || group.name || ''}` : 'Gruppo selezionato';
            })()
          : 'Tutti i gruppi';
                
        let csvContent = `Utilizzo Flotta - ${year} - ${groupName}\n\n`;
        csvContent += 'Mese,Utilizzo Previsto (%),N° Prenotazioni,Durata Media Prenot. (gg.),Utilizzo Reale (%),N° Movo,Durata Media Movo (gg.),N° Veicoli\n';
        
        data.forEach(item => {
          csvContent += `${item.name},${item.expectedUtilization},${item.reservationCount},${item.avgReservationDuration},${item.actualUtilization},${item.rentCount},${item.avgRentDuration},${item.vehicleCount}\n`;
        });
        
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        
        link.setAttribute('href', url);
        link.setAttribute('download', `utilizzo-flotta-${year}-${groupName.replace(/\s+/g, '-').toLowerCase()}.csv`);
        
        document.body.appendChild(link);
        link.click();
        
        setTimeout(() => {
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }, 100);
        
        toast.success('File CSV generato con successo');
      } catch (error) {
        console.error('Errore durante l\'esportazione in CSV:', error);
        toast.error('Errore durante la generazione del file CSV');
      }
    };

    const downloadChart = () => {
      try {
        const chartContainer = document.querySelector('.recharts-wrapper');
        if (!chartContainer) {
          toast.error('Grafico non disponibile');
          return;
        }
        
        const svgElement = chartContainer.querySelector('svg');
        if (!svgElement) {
          toast.error('Grafico SVG non trovato');
          return;
        }
        
        const groupName = selectedGroup 
          ? (() => {
              const group = groups.find(g => g._id === selectedGroup);
              return group ? `${group.mnemonic} ${group.description || group.name || ''}` : 'Gruppo selezionato';
            })()
          : 'Tutti i gruppi';
        
        const titleText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        titleText.setAttribute('x', '50%');
        titleText.setAttribute('y', '20');
        titleText.setAttribute('text-anchor', 'middle');
        titleText.setAttribute('font-size', '16');
        titleText.setAttribute('font-weight', 'bold');
        titleText.textContent = `Utilizzo Flotta - ${year} - ${groupName}`;
        
        svgElement.insertBefore(titleText, svgElement.firstChild);
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const svgBlob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
        const svgUrl = URL.createObjectURL(svgBlob);
        
        const link = document.createElement('a');
        link.setAttribute('href', svgUrl);
        link.setAttribute('download', `utilizzo-flotta-grafico-${year}-${groupName.replace(/\s+/g, '-').toLowerCase()}.svg`);
        
        document.body.appendChild(link);
        link.click();
        
        setTimeout(() => {
          document.body.removeChild(link);
          URL.revokeObjectURL(svgUrl);
        }, 100);
        
        toast.success('Grafico scaricato con successo');
      } catch (error) {
        console.error('Errore durante il download del grafico:', error);
        toast.error('Errore durante il download del grafico');
      }
    };

    const printReport = () => {
      try {
        const chartContainer = document.querySelector('.recharts-wrapper');
        let chartImageUrl = '';
        
        const continuePrinting = () => {
          const printContent = document.createElement('div');
          
          const selectedGroupName = selectedGroup 
            ? (() => {
                const group = groups.find(g => g._id === selectedGroup);
                return group ? `${group.mnemonic} - ${group.description || group.name || ''}` : 'Gruppo selezionato';
              })()
            : 'Tutti i gruppi';
          
          let chartHtml = '';
          if (chartImageUrl) {
            chartHtml = `
              <div style="margin: 0; display: flex; flex-direction: column; height: 46vh; position: relative;">
                <div style="flex-grow: 1; display: flex; align-items: center; justify-content: center; min-height: 42vh;">
                  <img src="${chartImageUrl}" style="max-width: 100%; max-height: 100%;" alt="Grafico utilizzo flotta" />
                </div>
                
                <div style="position: absolute; bottom: 0; left: 0; right: 0; display: flex; font-size: 10px; justify-content: center; background-color: rgba(255,255,255,0.8); padding: 3px 0;">
                  <div style="margin-right: 30px; display: flex; align-items: center;">
                    <div class="legend-dot-green" style="width: 15px; height: 15px; background-color: #22c55e !important; border-radius: 50%; margin-right: 5px; border: 1px solid #999;"></div>
                    <span><strong>% utilizzo previsto</strong></span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <div class="legend-dot-orange" style="width: 15px; height: 15px; background-color: #f97316 !important; border-radius: 50%; margin-right: 5px; border: 1px solid #999;"></div>
                    <span><strong>% utilizzo reale</strong></span>
                  </div>
                </div>
              </div>
            `;
          }
          
          printContent.innerHTML = `
            <div style="padding: 0.3cm; font-family: Arial, sans-serif; max-width: 100%; height: 95vh; display: flex; flex-direction: column;">
              <div style="margin-bottom: 0.3vh;">
                <h1 style="text-align: center; margin: 0; font-size: 16px;">Utilizzo Flotta - ${year}</h1>
                <h2 style="text-align: center; margin: 0; font-style: italic; font-size: 13px;">Gruppo: ${selectedGroupName}</h2>
              </div>
              
              ${chartHtml}
              
              <div style="height: 45vh; display: flex; flex-direction: column; justify-content: space-between; margin-top: 0.3vh;">
                <table style="width: 100%; border-collapse: collapse; font-size: 9px; flex-grow: 1;">
                  <thead>
                    <tr style="background-color: #f3f4f6;">
                      <th style="border: 1px solid #e5e7eb; padding: 2px; text-align: left;">Mese</th>
                      <th style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">Utilizzo Previsto (%)</th>
                      <th style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">N° Prenotazioni</th>
                      <th style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">Durata Media Prenot. (gg.)</th>
                      <th style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">Utilizzo Reale (%)</th>
                      <th style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">N° Movo</th>
                      <th style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">Durata Media Movo (gg.)</th>
                      <th style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">N° Veicoli</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${data.map(item => `
                      <tr>
                        <td style="border: 1px solid #e5e7eb; padding: 2px;">${item.name}</td>
                        <td style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">${item.expectedUtilization}%</td>
                        <td style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">${item.reservationCount}</td>
                        <td style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">${item.avgReservationDuration}</td>
                        <td style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">${item.actualUtilization}%</td>
                        <td style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">${item.rentCount}</td>
                        <td style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">${item.avgRentDuration}</td>
                        <td style="border: 1px solid #e5e7eb; padding: 2px; text-align: right;">${item.vehicleCount}</td>
                      </tr>
                    `).join('')}
                  </tbody>
                </table>
                
                <div style="text-align: right; font-style: italic; font-size: 8px; margin-top: 3px;">
                  Data di stampa: ${new Date().toLocaleDateString('it-IT')}
                </div>
              </div>
            </div>
          `;
    
          const printWindow = window.open('', '_blank');
          printWindow.document.write(`
            <html>
              <head>
                <title>Utilizzo Flotta - ${year} - ${selectedGroupName}</title>
                <style>
                  @media print {
                    @page {
                      size: landscape;
                      margin: 0;
                    }
                    body {
                      font-family: Arial, sans-serif;
                      margin: 0;
                      padding: 0;
                    }
                    h1 {
                      font-size: 16px;
                      margin: 0;
                    }
                    h2 {
                      font-size: 13px;
                      margin: 0;
                    }
                    table {
                      font-size: 9px;
                    }
                    th, td {
                      padding: 2px;
                    }
                    .legend-dot-green {
                      background-color: #22c55e !important;
                      print-color-adjust: exact;
                      -webkit-print-color-adjust: exact;
                    }
                    .legend-dot-orange {
                      background-color: #f97316 !important;
                      print-color-adjust: exact;
                      -webkit-print-color-adjust: exact;
                    }
                  }
                </style>
              </head>
              <body>
                ${printContent.outerHTML}
                <script>
                  window.onload = function() {
                    // Assicuriamoci che i colori vengano preservati nella stampa
                    setTimeout(function() {
                      window.print();
                      window.setTimeout(function() { window.close(); }, 500);
                    }, 300);
                  }
                </script>
              </body>
            </html>
          `);
        
          printWindow.document.close();
        };
        
        if (chartContainer) {
          const svgElement = chartContainer.querySelector('svg');
          if (svgElement) {
            const svgData = new XMLSerializer().serializeToString(svgElement);
            const svgBlob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
            chartImageUrl = URL.createObjectURL(svgBlob);
            
            setTimeout(() => {
              continuePrinting();
              setTimeout(() => URL.revokeObjectURL(chartImageUrl), 1000);
            }, 100);
          } else {
            continuePrinting();
          }
        } else {
          continuePrinting();
        }
      } catch (error) {
        console.error('Errore durante la stampa:', error);
        toast.error('Errore durante la stampa del report');
      }
    };

    return (
      <Page bodyClassName={'pb-4'}>
        <CardsHeader
          title="Dettagli report"
          buttons={[
            {
              btnStyle: 'lightSlateTransparent',
              children: 'Estrai Excel',
              onClick: exportToExcel,
            },
            {
              btnStyle: 'lightSlateTransparent',
              children: 'Scarica Grafico',
              onClick: downloadChart,
            },
            {
              btnStyle: 'lightSlateTransparent',
              children: 'Stampa',
              onClick: printReport,
            },
          ]}
        />

        <div className="px-6">
          <div className="bg-white rounded-2xl shadow-lg m-4 overflow-hidden mx-0 mt-0 p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Utilizzo Flotta</h3>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <select 
                    className="border rounded px-2 py-1"
                    value={selectedGroup}
                    onChange={(e) => setSelectedGroup(e.target.value)}
                    style={{ minWidth: '180px' }}
                  >
                    <option value="">Tutti i gruppi</option>
                    {groups.map(group => (
                      <option key={group._id} value={group._id}>
                        {group.mnemonic} - {group.description || group.name || ''}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div className="flex items-center gap-2">
                  <select 
                    className="border rounded px-2 py-1"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    style={{ minWidth: '80px' }}
                  >
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex justify-end mb-2 gap-4">
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-full bg-green-400 mr-2"></div>
                <span>% utilizzo previsto</span>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-full bg-orange-500 mr-2"></div>
                <span>% utilizzo reale</span>
              </div>
            </div>

            {isLoading ? (
              <div className="w-full h-64 flex items-center justify-center">
                <p>Caricamento in corso...</p>
              </div>
            ) : (
              <div className="w-full h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    data={data}
                    margin={{ top: 30, right: 20, left: 0, bottom: 40 }}
                    barGap={0}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis 
                      dataKey="name" 
                      axisLine={true}
                      tickLine={false}
                      dy={10}
                    />
                    <YAxis 
                      tickFormatter={(tick) => `${tick}%`} 
                      domain={[0, 100]} 
                    />
                    <Tooltip 
                      formatter={(value, name) => {
                          if (name === 'expectedUtilization') return [`${value}%`, 'Utilizzo previsto'];
                          if (name === 'actualUtilization') return [`${value}%`, 'Utilizzo reale'];
                          return value;
                      }}
                      labelFormatter={(label) => `${label}`}
                    />
                    <defs>
                      <linearGradient id="colorExpected" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#22c55e" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#22c55e" stopOpacity={0.2}/>
                      </linearGradient>
                    </defs>
                    <Area 
                      type="monotone" 
                      dataKey="expectedUtilization" 
                      fill="url(#colorExpected)" 
                      stroke="#22c55e" 
                      fillOpacity={0.8}
                      isAnimationActive={false}
                    >
                      <LabelList 
                        dataKey="expectedUtilization" 
                        content={renderExpectedLabel}
                      />
                    </Area>
                    <Bar 
                      dataKey="actualUtilization" 
                      fill="#f97316" 
                      barSize={25}
                      isAnimationActive={false}
                    >
                      <LabelList
                        dataKey="actualUtilization"
                        content={renderActualLabel}
                      />
                    </Bar>
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            )}

            <div className="flex justify-end mt-4">
              {data.length > 0 && (
                <button 
                  onClick={() => setShowDetails(!showDetails)}
                  className="text-blue-500 hover:text-blue-600 transition-colors text-sm flex items-center"
                >
                  {showDetails ? 'Nascondi dettagli' : 'Mostra dettagli'}
                  <FaAngleDown 
                    className={`ml-1 transition-transform duration-300 ${
                      showDetails ? 'transform rotate-180' : ''
                    }`} 
                  />
                </button>
              )}
            </div>
            <div 
              className={`overflow-hidden transition-all duration-300 ${
                showDetails ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
              }`}
            >
              {renderDataTable()}
            </div>
          </div>
        </div>
      </Page>
    );
  };

  return <ReportContent />;
};

export default DashboardReport;