import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../../store/UserContext';
import SettingsPage from '../../../components/Settings/SettingsPage';
import TableHeader from '../../../components/UI/TableHeader';
import { useHistory } from 'react-router-dom';

import { CLIENT_ROLE_ADMIN } from '../../../utils/Utils';
import WidgetConfigTable from '../../../components/Widget/WidgetConfigTable';

const Cargos = () => {
  const form = useForm();
  const history = useHistory();

  const { data: userData } = useContext(UserContext);

  return (
    <SettingsPage canAccess={[CLIENT_ROLE_ADMIN]}>
      <TableHeader
        tableName={'Widget'}
        buttons={[
          {
            function: () => {
              history.push('/settings/widget/crea');
            },
            label: 'Crea Nuovo',
            hidden: userData?.client.license?.licenseOwner === 'movolab',
          },
        ]}
      />
      <WidgetConfigTable />
    </SettingsPage>
  );
};

export default Cargos;
