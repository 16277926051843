import React from 'react';

const TableLengthSelector = ({ value, onChange }) => {
  return (
    <select
      className="form-select text-gray-800 w-48 rounded-lg px-3 pr-7 py-1 text-sm border-slate-300 bg-white min-w-[12rem]"
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
    >
      {[10, 25, 50, 100].map((length) => (
        <option key={length} value={length}>
          Mostra {length} righe
        </option>
      ))}
    </select>
  );
};

export default TableLengthSelector;
