import React, { useEffect, useState } from 'react';
import ElementLabel from './ElementLabel';
import { fetchFranchises } from '../Rents/rentElements/Franchises';
import { convertPrice } from '../../utils/Prices';

const FranchisesBox = ({ rentResevation, phase, className = '', children = null, addExtras = false, ...props }) => {
  const [frachisesLabels, setFranchiseLabels] = useState([]);

  const extras = addExtras ? rentResevation?.pickUpExtraServices || rentResevation?.reservationExtraServices : [];

  const franchisesExtras = {
    kasko: extras?.find((extra) => extra?.manualRules?.some((rule) => rule.parameter === 'kasko')),
    if: extras?.find((extra) => extra?.manualRules?.some((rule) => rule.parameter === 'furto')),
    rca: extras?.find((extra) => extra?.manualRules?.some((rule) => rule.parameter === 'rca')),
  };

  const fetchFranchiseLabels = async () => {
    setFranchiseLabels(await fetchFranchises(rentResevation, phase));
  };

  useEffect(() => {
    fetchFranchiseLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getManualRulePercent = (extra, type) => 
    (extra?.manualRules?.find((rule) => rule?.parameter === type))?.reduction;

  const applyReduction = (originalValue, extra, type) => {
    const reduction = getManualRulePercent(extra, type);

    return reduction ? (originalValue - (originalValue * (reduction / 100))) : originalValue;
  }

  return (
    <div className={`flex gap-2 ${className}`} {...props}>
      {children}

      {frachisesLabels?.kasko ? (
        <div>
          <ElementLabel bgColor={franchisesExtras?.kasko ? 'bg-gray-400' : 'bg-gray-600'}>
            Franchigia Kasko <strong className="font-bold">{convertPrice(applyReduction(frachisesLabels.kasko.value, franchisesExtras?.kasko, 'kasko'))}</strong>
          </ElementLabel>

          {franchisesExtras?.kasko ? (
            <ElementLabel bgColor="bg-gray-600" className="block mt-1 !whitespace-normal">
              <strong className="font-bold">{franchisesExtras?.kasko.name}</strong>
              <div className="text-[0.65rem] leading-4">{franchisesExtras?.kasko.description}</div>
            </ElementLabel>
          ) : null}
        </div>
      ) : null}

      {frachisesLabels?.rca ? (
        <div>
          <ElementLabel bgColor={franchisesExtras?.rca ? 'bg-orange-400' : 'bg-orange-600'}>
            Franchigia RCA <strong className="font-bold">{convertPrice(applyReduction(frachisesLabels.rca.value, franchisesExtras?.rca, 'rca'))}</strong>
          </ElementLabel>

          {franchisesExtras?.rca ? (
            <ElementLabel bgColor="bg-orange-600" className="block mt-1 !whitespace-normal">
              <strong className="font-bold">{franchisesExtras?.rca.name}</strong>
              <div className="text-[0.65rem] leading-4">{franchisesExtras?.rca.description}</div>
            </ElementLabel>
          ) : null}
        </div>
      ) : null}

      {frachisesLabels?.if ? (
        <div>
          <ElementLabel bgColor={franchisesExtras?.if ? 'bg-yellow-500' : 'bg-yellow-600'}>
            Franchigia I/F <strong className="font-bold">{convertPrice(applyReduction(frachisesLabels.if.value, franchisesExtras?.if, 'furto'))}</strong>
          </ElementLabel>

          {franchisesExtras?.if ? (
            <ElementLabel bgColor="bg-yellow-600" className="block mt-1 !whitespace-normal">
              <strong className="font-bold">{franchisesExtras?.if.name}</strong>
              <div className="text-[0.65rem] leading-4">{franchisesExtras?.if.description}</div>
            </ElementLabel>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default FranchisesBox;
