import React from 'react';
import DisplayDateTime from '../UI/dates/DisplayDateTime';
import Button from '../UI/buttons/Button';

const WidgetConfigTableItem = (item) => {
  return (
    <tr>
      <td className="px-5 py-3 whitespace-nowrap w-32">
        <p className="text-left font-semibold text-gray-600">{item?.widget?.name}</p>
      </td>
      <td className="px-5 py-3 whitespace-nowrap w-32">
        <p className="text-left font-semibold text-gray-600">{item?.widget?.widgetId}</p>
      </td>
      <td className="px-5 py-3 whitespace-nowrap w-32">
        <p className="text-left font-semibold text-gray-600">
          {item?.widget?.workflowPayNow?.name}
        </p>
      </td>
      <td className="px-5 py-3 whitespace-nowrap w-32">
        <p className="text-left font-semibold text-gray-600">
          {item?.widget?.workflowPayLater?.name}
        </p>
      </td>
      <td className="px-5 py-3 whitespace-nowrap w-32">
        <DisplayDateTime date={item?.widget?.createdAt} displayType={'flat'} />
      </td>
      <td className="space-x-2">
        <Button to={`/settings/widget/${item?.widget?._id}`} btnStyle="tableItemAction">
          Dettagli &raquo;
        </Button>
      </td>
    </tr>
  );
};

export default WidgetConfigTableItem;
