import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { CLIENT_ROLE_ADMIN, http } from '../../../utils/Utils';
import TableHeader from '../../../components/UI/TableHeader';
import PromoCodesTable from '../../../components/PromoCodes/PromoCodesTable';
import SettingsPage from '../../../components/Settings/SettingsPage';

const PromoCodes = () => {
  const history = useHistory();

  const [promoCodesCount, setPromoCodesCount] = useState(0);
  const [promoCodes, setPromoCodes] = useState([]); // eslint-disable-line

  useEffect(() => {
    fetchPromoCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPromoCodes = async () => {
    try {
      const response = await http({ url: '/pricing/promoCode' });

      setPromoCodesCount(response.count);
      setPromoCodes(response.promoCodes);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <SettingsPage canAccess={CLIENT_ROLE_ADMIN}>
      <TableHeader
        tableName={'Codici Promo'}
        buttons={[
          {
            function: () => {
              history.push(`/settings/codicipromo/crea`);
            },
            label: '+',
          },
        ]}
        length={promoCodesCount}
      />

      <PromoCodesTable />
    </SettingsPage>
  );
};

export default PromoCodes;
