import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { MOVOLAB_ROLE_ADMIN, http } from '../../../../utils/Utils';

import AdminPage from '../../../../components/Admin/AdminPage';
import Button from '../../../..//components/UI/buttons/Button';
import RevenueShareMonthlyRecapDetails from '../../../../components/RevenueShares/RevenueShareMonthlyRecapDetails';

const AdminRevenueShareMonthlyRecap = () => {
  const params = useParams();
  const history = useHistory();
  const [revenueShareMonthlyRecap, setRevenueShareMonthlyRecap] = useState({});
  const [movolabOwesClient, setMovolabOwesClient] = useState(0);

  useEffect(() => {
    fetchRevenueShareMonthlyRecap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRevenueShareMonthlyRecap = async () => {
    try {
      const response = await http({
        url: `/clientPayments/revenueShareMonthlyRecap/${params.id}`,
      });

      setRevenueShareMonthlyRecap(response);
      let expectedMovolabRevenue = 0;
      let receivedMovolabRevenue = 0;
      let expectedClientRevenue = 0;
      let receivedClientRevenue = 0;

      await Promise.all(
        response.revenueShares.map(async (revenueShare) => {
          expectedMovolabRevenue += Number(revenueShare?.movolabAmount?.expectedRevenue || 0);
          receivedMovolabRevenue += Number(revenueShare?.movolabAmount?.receivedRevenue || 0);
          expectedClientRevenue += Number(revenueShare?.clientAmount?.expectedRevenue || 0);
          receivedClientRevenue += Number(revenueShare?.clientAmount?.receivedRevenue || 0);
        }),
      );

      // Calculate how much Movolab owes to the client
      const movolabOwesClient = expectedClientRevenue - receivedClientRevenue;
      setMovolabOwesClient(movolabOwesClient);

      console.log('Movolab owes to the client', movolabOwesClient.toFixed(2));
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <AdminPage canAccess={MOVOLAB_ROLE_ADMIN}>
      <div className="p-4 w-full">
        <div className="flex space-x-4 justify-between">
          <div>
            <Button btnStyle={'lightGray'} onClick={() => history.goBack()}>
              Indietro
            </Button>
          </div>
        </div>
        <RevenueShareMonthlyRecapDetails
          monthlyRecap={revenueShareMonthlyRecap}
          movolabOwesClient={movolabOwesClient}
          showDetails={true}
        />
        <div className="mb-4 mt-2"></div>
      </div>
    </AdminPage>
  );
};

export default AdminRevenueShareMonthlyRecap;
