import React, { useContext, useEffect, useState } from 'react';
import Page from '../../../components/Dashboard/Page';
import WhiteBox from '../../../components/UI/WhiteBox';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { http } from '../../../utils/Utils';
import { CLIENT_ROLE_ADMIN, CLIENT_ROLE_OPERATOR } from '../../../utils/Utils';
// import FinePrints from '../../../utils/FinePrints';
import CardsHeader from '../../../components/UI/CardsHeader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SignAgreement from '../../../components/Documents/SignAgreement';
import html2pdf from 'html2pdf.js';
import LoadingSpinner from '../../../assets/icons/LoadingSpinner';
import ElementLabel from '../../../components/UI/ElementLabel';
import { UserContext } from '../../../store/UserContext';

const PrintBox = ({ title, children }) =>
  <div className="flex m-2 border-gray-500 border-2 rounded-2xl overflow-hidden items-center bg-[#f9fafd]">
    {title ? <div className="w-10 relative">
      <h3 className="font-extrabold text-2xl origin-center absolute -rotate-90 text-nowrap left-[-10.5rem] -top-4 text-center w-96">{title}</h3>
    </div> : null}
    <div className="flex-1">
      {children}
    </div>
  </div>


const RentPrintCom = () => {
  const params = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const phase = params.phase === 'dropOff' ? 'dropOff' : 'pickUp';

  const userContext = useContext(UserContext);
  const { data: userData } = userContext;
  console.log(userData);

  const page2PDF = async (
    element,
    filename = 'dettaglio_movo.pdf',
    download = true,
  ) => {
    const opt = {
      margin: 0,
      filename: filename,
      image: { type: 'jpeg', quality: 0.65 },
      html2canvas: { scale: 2, width: 2000, allowTaint: true, useCORS: true },
      jsPDF: { orientation: 'landscape' },
    };

    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet.insertRule('#rentPrint { width: 2000px; }');
    style.sheet.insertRule('.no-pdf { display: none; }');

    // Html3pdf bugfix with tailwind preflight
    style.sheet.insertRule('body > div:last-child img { display: inline-block; }');

    setIsLoading(true);

    const worker = html2pdf().set(opt).from(element).toPdf();

    if (download) {
      worker.output('blob').save().then((res) => {
        style.remove();
        setIsLoading(false);
        toast.success(
          'Documento scaricato in formato PDF, è ora possibile firmarlo e ricaricarlo nella pagina del movo.',
        );
      });
    }
  };

  return (
    <Page canAccess={[CLIENT_ROLE_ADMIN, CLIENT_ROLE_OPERATOR]} bodyClassName={'pb-4'}>
      <CardsHeader
        className="print:hidden"
        title="Stampa scheda comodato"
        buttons={[
          {
            btnStyle: 'lightSlateTransparent',
            children: '« Indietro',
            onClick: () => history.goBack(),
          },
          {
            btnStyle: 'blue',
            children: 'Stampa',
            onClick: () => {
              page2PDF(document.getElementById('rentPrint'), `scheda_comodato_compilabile.pdf`, true)
            },
          },
        ]}
      />
      {isLoading && (
        <div className="w-full h-full absolute top-0 left-0 z-10 bg-slate-800 bg-opacity-10 flex items-center justify-center">
          <div className="w-24 h-24">
            <LoadingSpinner />
            <span className="sr-only">Carico...</span>
          </div>
        </div>
      )}
      <div className="text-gray-800" id="rentPrint">
        <WhiteBox className="mx-6">
          <PrintBox>
            <div className="flex items-center px-6 py-4">
              <div>
                <ElementLabel bgColor="bg-yellow-500" className="!text-xl uppercase font-bolder !rounded-3xl px-4">Comodato</ElementLabel>
              </div>
              <div className="flex-1 font-extrabold text-2xl text-center">
                {userData?.client?.ragioneSociale}
              </div>
              <div className="text-right text-sm">
                {userData?.client?.address?.address}<br />
                {userData?.client?.address?.zipCode} {userData?.client?.address?.city} ({userData?.client?.address?.province})<br />
                P.IVA: {userData?.client?.partitaIva}
              </div>
            </div>
          </PrintBox>
          <PrintBox>
            <div className="flex font-extrabold text-xl px-3 py-1">
              <h3 className="w-1/5">Targa:</h3>
              <h3 className="flex-1">Marca / modello:</h3>
              <h3 className="w-1/6">Gruppo:</h3>
            </div>
          </PrintBox>
          <PrintBox>
            <div className="flex items-center">
              <div className="w-10 relative">
                <h3 className="font-extrabold text-lg origin-center absolute -rotate-90 text-nowrap left-[-11.25rem] -top-4 text-center w-96">Conducente</h3>
              </div>
              <div>
                <img src="/rentFillable_driver.png" className="mt-0 px-2" />
              </div>
              <div className="w-10 relative">
                <h3 className="font-extrabold text-lg origin-center absolute -rotate-90 text-nowrap left-[-11.25rem] -top-4 text-center w-96">Cliente</h3>
              </div>
              <div>
                <img src="/rentFillable_client.jpg" className="mt-0 px-2" />
              </div>
            </div>
          </PrintBox>
          <PrintBox title="Apertura">
            <img src="/rentFillable_pickup.png" className="mt-0 px-2" />
          </PrintBox>
          <PrintBox title="Chiusura">
            <img src="/rentFillable_dropoff.png" className="mt-0 px-2" />
          </PrintBox>
          <PrintBox title="Condizioni di utilizzo">
            <SignAgreement
              // rentId={rent._id}
              phase={phase}
              // signatureData={rent?.signature}
              isPrint={true}
              // showSignatureBox={showSignatureBox}
              movoType={'COM'}
              consentsCol1={[]}
              consentsTitle={false}
              className=""
              termsAndConditionsTitle={false}
            />
          </PrintBox>
        </WhiteBox>
      </div>
    </Page>
  );
};
export default RentPrintCom;
