import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { TextField } from '../Form/TextField';

const SearchAddress = ({ returnAddress, onChange, startAddress = '', containerClassName = '', ...props }) => {
  const [address, setAddress] = useState(null);

  const getComponent = (address_components, type) => {
    return address_components?.find((component) => component.types.includes(type));
  };

  const handleChange = (address) => {
    setAddress(address);

    if (onChange) {
      onChange(address);
    }
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        const address = results[0]?.address_components;

        if (address) {
          const address_components = {
            street: getComponent(address, 'route')?.long_name,
            houseNumber: getComponent(address, 'street_number')?.long_name,
            city: getComponent(address, 'administrative_area_level_3')?.long_name ||
              getComponent(address, 'locality')?.long_name ,
            province: getComponent(address, 'administrative_area_level_2')?.short_name,
            nation: getComponent(address, 'country')?.long_name,
            zipCode: getComponent(address, 'postal_code')?.long_name,
            location: getComponent(address, 'neighborhood')?.long_name || 
              getComponent(address, 'locality')?.long_name ||
              getComponent(address, 'administrative_area_level_3')?.long_name,
          };

          returnAddress(results[0].formatted_address, address_components, results[0]?.geometry?.location);
        }
        
      })
      .catch((error) => {
        console.error('Error', error);
      });
  };

  return (
    <PlacesAutocomplete value={address !== null ? address : startAddress} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <TextField inputClassName="w-full" {...getInputProps({ ...props })}>
          {suggestions.length > 0 && (
            <ul className="autocomplete-dropdown-container border border-black rounded-b overflow-hidden">
              {loading && <div className="px-3 py-1 bg-white">Caricamento...</div>}
              {suggestions.map((suggestion, index) =>
                <li
                  key={index}
                  children={suggestion.description}
                  {...getSuggestionItemProps(suggestion, { className: `px-3 py-1 cursor-pointer ${suggestion.active ? 'bg-slate-100' : 'bg-white'}` })}
                />
              )}
            </ul>
          )}
        </TextField>
      )}
    </PlacesAutocomplete>
  );
};

export default SearchAddress;
