import React, { useState } from 'react';
import Modal from '../UI/Modal';
import Management from '../Vehicles/Vehicles/Update/Management';
import Button from '../UI/buttons/Button';
import { http } from '../../utils/Utils';

export function ModalVehicleUpdateRentalLocation({ showModal, updateShowModal, closeShowModalAndUpdate, reservationLocation, vehicle }) {
  const [theVehicle, setVehicle] = useState(vehicle);

  const fetchVehicle = async (id) => {
    try {
      const response = await http({ url: `/vehicles/vehicle/${id}` });
      setVehicle(response);
    } catch (e) { }
  }

  useState(() => {
    if (!vehicle?.rentalLocation?._id) {
      fetchVehicle(vehicle?._id);
    }
  }, [vehicle?._id]);

  return (
    <Modal
      headerChildren={`Cambia punto nolo per ${vehicle.plate}`}
      isVisible={showModal}
      onClose={updateShowModal}
    // innerClassName={'px-3 py-4'}
    >
      {reservationLocation ? <p>
        <strong className="font-semibold">Punto nolo prenotazione:</strong> {reservationLocation.name}
      </p> : null}

      <Management vehicle={theVehicle} fetchVehicle={closeShowModalAndUpdate} updateStepDone={() => { }} isVertical />

      <div className="flex justify-end w-full pt-2">
        <Button className="!py-1" btnStyle="white" form="saveVehicle">
          Sposta veicolo
        </Button>
      </div>
    </Modal>
  );
}

export default ModalVehicleUpdateRentalLocation;
