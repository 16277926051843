import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import toast from 'react-hot-toast';
import { http } from '../../utils/Utils';
import { TextField } from '../Form/TextField';
import Button from '../UI/buttons/Button';
import FormLabel from '../UI/FormLabel';
import WhiteBox from '../../components/UI/WhiteBox';
import { updateWorkflowParams } from '../../utils/Workflow';
import { SelectField } from '../Form/SelectField';

const ReservationPickupDropoffInfo = ({ reservation, updatePrice, viewMode = false, fromCorporate = false }) => {
  const form = useForm();
  const [rentalLocations, setRentalLocations] = useState([]);

  const fetchRentalLocations = async (workflowId) => {
    try {
      const response = await updateWorkflowParams(workflowId);

      if (fromCorporate) {
        const workflow = await http({ url: `/workflow/${workflowId}` });
        setRentalLocations(workflow.rentalLocations);
      } else {
        setRentalLocations(response.rentalLocations);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  useEffect(() => {
    if (reservation) {
      form.setValue('pickUpDate', moment(reservation.pickUpDate).format().slice(0, 16));
      form.setValue('dropOffDate', moment(reservation.dropOffDate).format('YYYY-MM-DDTHH:mm'));
      form.setValue('pickUpLocation', reservation.pickUpLocation._id);
      form.setValue('dropOffLocation', reservation.dropOffLocation._id);
      fetchRentalLocations(reservation.workflow._id);
    }
  }, [reservation]); // eslint-disable-line

  const updateDates = async (e) => {
    try {
      e.preventDefault();

      if (form.getValues('pickUpDate') > form.getValues('dropOffDate')) {
        return toast.error('La data di apertura è successiva a quella di chiusura');
      }

      const data = {
        pickUpDate: form.getValues('pickUpDate'),
        dropOffDate: form.getValues('dropOffDate'),
        pickUpLocation: form.getValues('pickUpLocation'),
        dropOffLocation: form.getValues('dropOffLocation'),
      };

      await http({
        url: `/reservations/${reservation._id}`,
        method: 'PUT',
        form: data,
      });

      await updatePrice();
      toast.success('Prenotazione Aggiornata');
    } catch (error) {
      console.error(error);
      toast.error(error?.error || 'Non puoi aggiornare la prenotazione');
    }
  };

  const isUpdateDatesDisabled = reservation?.state !== 'draft';

  return (
    <WhiteBox className="mx-0 p-6">
      <div className="transition-all duration-1000">
        <form onSubmit={updateDates}>
          <fieldset disabled={form.formState.isSubmitting} className="w-full">
            <div className="flex gap-x-3 flex-wrap 2xl:flex-nowrap">
              <SelectField
                form={form}
                name="pickUpLocation"
                placeholder="Luogo consegna"
                label="Luogo consegna"
                disabled={isUpdateDatesDisabled}
                options={rentalLocations.map((rentalLocation) => ({ label: rentalLocation.name, value: rentalLocation._id }))}
                className="w-1/5 min-w-32"
              />
              <TextField
                form={form}
                name="pickUpDate"
                type="datetime-local"
                placeholder="Inizio"
                label="Data consegna"
                disabled={isUpdateDatesDisabled}
                min={moment().format('YYYY-MM-DDTHH:mm')}
                className="w-1/5 min-w-32"
              />
              <SelectField
                form={form}
                name="dropOffLocation"
                placeholder="Luogo ritiro"
                label="Luogo ritiro"
                disabled={isUpdateDatesDisabled}
                options={rentalLocations.map((rentalLocation) => ({ label: rentalLocation.name, value: rentalLocation._id }))}
                className="w-1/5 min-w-32"
              />
              <TextField
                form={form}
                name="dropOffDate"
                type="datetime-local"
                placeholder="Fine"
                label="Data ritiro"
                disabled={isUpdateDatesDisabled}
                min={moment(form.getValues('pickUpDate')).format('YYYY-MM-DD HH:mm')}
                className="w-1/5 min-w-32"
              />
              {reservation?.state === 'draft' && (
                <div>
                  <FormLabel>&nbsp;</FormLabel>
                  <Button btnStyle="inFormStyle">Aggiorna</Button>
                </div>
              )}
            </div>
          </fieldset>
        </form>
      </div>
    </WhiteBox>
  );
};

export default ReservationPickupDropoffInfo;
