import React from 'react';
import ResidenceForm from '../User/ResidenceForm';
import Button from '../../UI/buttons/Button';

const NewResidence = ({ type, updateSection, userId, returnUser, importUser, mode }) => {

  const onUserUpdate = (user) => {
    if (type === 'driver' || type === 'secondDriver') {
      updateSection('driving-license');
    } else {
      returnUser(user);
      updateSection('modal-completed');
    }
  };
  
  const importUserFunction = async (e) => {
    e.preventDefault();
    try {
      importUser(userId);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <ResidenceForm userId={userId} onUserUpdate={onUserUpdate} formName="residenceForm" />

      <div className="flex justify-end space-x-2 mt-2">
        <Button btnStyle="white" className="!py-1"
          onClick={(e) => {
            e.preventDefault();
            updateSection('personal-details');
          }}
        >
          Indietro
        </Button>
        {/* showAddress */ true ? (
          <>
            {type === 'driver' || type === 'secondDriver' ? (
              <Button btnStyle="white" className="!py-1" form="residenceForm">Avanti</Button>
            ) : (
              <>
                <Button
                  type="button"
                  btnStyle="white"
                  className="!py-1"
                  onClick={(e) => {
                    e.preventDefault();
                    updateSection('driving-license');
                  }}
                >
                  Aggiungi patente
                </Button>
                <Button btnStyle="blue" className="!py-1" form="residenceForm">{mode === 'modify' ? 'Salva' : 'Completa'}</Button>
              </>
            )}
            {mode === 'import' ? (
              <Button btnStyle="gray" className="!py-1"
                onClick={(e) => {
                  importUserFunction(e);
                }}
              >
                Importa Utente
              </Button>
            ) : (
              <></>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default NewResidence;
