import React from 'react';
import { CLIENT_ROLE_ADMIN } from '../../../utils/Utils';
import { useHistory, useParams } from 'react-router-dom';

import UpdatePromoCodeGeneral from '../../../components/PromoCodes/UpdatePromoCodeGeneral';
import SettingsPage from '../../../components/Settings/SettingsPage';
import WhiteBox from '../../../components/UI/WhiteBox';
import CardsHeader from '../../../components/UI/CardsHeader';

const EditPromoCode = () => {
  const params = useParams();
  const history = useHistory();

  const mode = params.id ? 'edit' : 'create';

  return (
    <SettingsPage canAccess={CLIENT_ROLE_ADMIN} hasBox={false}>
      <CardsHeader
        title={`${mode === 'edit' ? 'Modifica' : 'Crea'} codice promo`}
        buttons={[
          {
            btnStyle: 'lightSlateTransparent',
            children: '« Indietro',
            onClick: () => history.goBack(),
          },
          {
            children: 'Salva codice promo',
            form: 'promoForm',
          },
        ]}
      />

      <WhiteBox className="mt-0 p-4">
        <UpdatePromoCodeGeneral mode={mode} />
      </WhiteBox>
    </SettingsPage>
  );
};

export default EditPromoCode;
