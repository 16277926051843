import React, { useEffect, useState } from 'react';
import Button from '../../UI/buttons/Button';
import { http } from '../../../utils/Utils';
import toast from 'react-hot-toast';
import Documents from '../../Documents/Documents';

const NewUserDocuments = ({ updateSection, prevSection = 'driving-license', userId, mode }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      if (!user.documents.find(doc => doc.label === 'identity_card')) {
        http({ url: `/users/document/${userId}`, method: 'PUT', form: { label: 'identity_card', name: 'Carta d\'identità' } })
          .then(() => fetchUser())
          .catch(err => {
            console.error(err);
            toast.error(err?.reason?.error || 'Errore');
          });
      }
    }
  }, [user]);

  const fetchUser = async () => {
    try {
      const response = await http({ url: `/users/${userId}` });
      setUser(response);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <>
      <h3 className="text-lg font-semibold mb-4">Documenti</h3>
      {user ?
        <Documents resourceType="user" resource={user} expanded={true} noCollapsible={true} boxClassName="" className="" />
        : null}
      <div className="flex justify-end space-x-2 mt-4">
        <Button
          btnStyle="white"
          className="!py-1"
          onClick={(e) => {
            e.preventDefault();
            updateSection(prevSection);
          }}
        >
          Indietro
        </Button>
        {mode === 'import' ? (
          <Button btnStyle="white" className="!py-1" onClick={() => updateSection('modal-completed')}>
            Importa Utente
          </Button>
        ) : (
          <Button btnStyle="blue" className="!py-1" onClick={() => updateSection('modal-completed')}>
            Completa
          </Button>
        )}
      </div>
    </>
  );
};

export default NewUserDocuments;
