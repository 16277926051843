import React from 'react';
import { MOVOLAB_ROLE_ADMIN } from '../../../utils/Utils';
import { useHistory, useParams } from 'react-router-dom';

import AdminPage from '../../../components/Admin/AdminPage';
import UpdatePromoCodeGeneral from '../../../components/PromoCodes/UpdatePromoCodeGeneral';
import Button from '../../../components/UI/buttons/Button';

const EditPromoCode = () => {
  const params = useParams();
  const history = useHistory();

  const mode = params.id ? 'edit' : 'create';

  return (
    <AdminPage canAccess={MOVOLAB_ROLE_ADMIN}>
      <div className="p-4">
        <Button onClick={() => history.goBack()} btnStyle="white">
          &laquo; Indietro
        </Button>

        <UpdatePromoCodeGeneral mode={mode} isAdmin />
      </div>
    </AdminPage>
  );
};

export default EditPromoCode;
