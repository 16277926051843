import React from 'react';
import { http } from '../../utils/Utils';
import toast from 'react-hot-toast';
import Table from '../UI/Table';
import moment from 'moment';
import ElementLabel from '../UI/ElementLabel';
import Button from '../UI/buttons/Button';

const PromoCodesTable = ({ isAdmin = false }) => {

  const fetchPromoCodes = async (skip = 0, limit = 10) => {
    try {
      const response = await http({ url: isAdmin ? `/pricing/promoCode/all?skip=${skip}&limit=${limit}` : `/pricing/promoCode?skip=${skip}&limit=${limit}` });
      return { resource: response.promoCodes, count: response.count };
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return <Table header={['Codice', 'Valido da', 'Valido a', isAdmin ? 'Cliente' : 'Utilizzi', isAdmin ? 'Utilizzi / Massimi' : 'Massimi', 'Attivo', 'Creato il', '']}
    fetchFunction={fetchPromoCodes}
    emptyTableMessage="Non è stato creato alcun codice promo"
    headClassName="text-gray-500 bg-gray-50 border-gray-200"
    itemsLayout={{
      code: (c, code) => <>{c} {code.description ? <><br /><span className="font-normal">{code.description}</span></> : null}</>,
      validFrom: c => moment(c).format('DD/MM/YYYY HH:SS'),
      validUntil: c => moment(c).format('DD/MM/YYYY HH:SS'),
      client: (client, code) => isAdmin ? (client?.ragioneSociale || 'N/D') : code.uses,
      maxUses: (maxUses, code) => isAdmin ? `${code.uses} / ${maxUses}` : maxUses,
      active: active => active ? <ElementLabel children="Attivo" bgColor="bg-green-500" /> : <ElementLabel children="Inattivo" bgColor="bg-red-500" />,
      createdAt: c => moment(c).format('DD/MM/YYYY HH:SS'),
      _id: id => <Button
        to={isAdmin ? `/admin/codicipromo/${id}/aggiorna` : `/settings/codicipromo/${id}/aggiorna`}
        btnStyle="tableItemAction"
      >
        Dettagli &raquo;
      </Button>
    }} />
};

export default PromoCodesTable;
