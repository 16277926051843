import React, { useEffect } from 'react';
import { http } from '../../../utils/Utils';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import FormLabel from '../../UI/FormLabel';
import { TextField } from '../../Form/TextField';
import { SelectField } from '../../Form/SelectField';
import FiscalCode from './FiscalCode';
import BirthPlaceSection from './BirthPlaceSection';

import { olderThan18 } from '../../../utils/Age';
import professionsJson from '../../../assets/professions';

const PersonalDetails = ({ user, onSubmitUpdate }) => {
  const params = useParams();

  // Change mode to 'onSubmit' to prevent validation on every keystroke
  const form = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (user) {
      // Use setTimeout to avoid immediate form reset conflicts
      setTimeout(() => {
        form.reset({
          name: user.name || '',
          surname: user.surname || '',
          gender: user.gender || '',
          profession: user.profession || '',
          birthDate: user.birthDate ? new Date(user.birthDate).toISOString().split('T')[0] : '',
          fiscalCode: user.fiscalCode || '',
          placeOfBirthNation: user.placeOfBirthNation || '',
          nationality: user.nationality || '',
          placeOfBirthProvince: user.placeOfBirthProvince || '',
          placeOfBirth: user.placeOfBirth || '',
          phone: user.phone || '',
          email: user.email || '',
          userType: user.userType || '',
        });
      }, 0);
    }
  }, [user]);

  const onSubmit = async (data) => {
    try {
      if (!olderThan18(data.birthDate)) {
        toast.error('Utente deve essere maggiorenne');
        return;
      }

      await http({
        method: 'PUT',
        url: `/users/${params.id}`,
        form: data,
      });
      onSubmitUpdate();
      toast.success('Utente aggiornato');
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  // Remove the watch calls that might be causing unnecessary re-renders
  // const watchEmail = form.watch('email');
  // const watchPhone = form.watch('phone');

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault(); // Explicitly prevent default form submission
          form.handleSubmit(onSubmit)(e);
        }}
        className="flex flex-wrap justify-between items-end"
        id="saveUserForm"
      >
        <div className="col-span-3">
          <FiscalCode user={user} form={form} />
          <div className="flex">
            <div className="mr-3 top-0 w-64">
              <FormLabel>Nome</FormLabel>
              <TextField
                form={form}
                name="name"
                type="string"
                placeholder="Nome"
                validation={{
                  required: { value: true, message: 'Nome Obbligatorio' },
                }}
              />
            </div>
            <div className="mr-3 top-0 w-64">
              <FormLabel>Cognome</FormLabel>
              <TextField
                form={form}
                name="surname"
                type="string"
                placeholder="Cognome"
                validation={{
                  required: { value: true, message: 'Cognome Obbiligatorio' },
                }}
              />
            </div>
            <div className="mr-3 w-64">
              <FormLabel>Genere</FormLabel>
              <SelectField
                form={form}
                name="gender"
                type="string"
                placeholder="Genere"
                options={[
                  { value: 'M', label: 'Maschile' },
                  { value: 'F', label: 'Femminile' },
                  { value: 'O', label: 'Altro' },
                ]}
                validation={{
                  required: { value: true, message: 'Genere Obbiligatorio' },
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div className="mr-3 top-0 w-64">
              <FormLabel>Professione</FormLabel>
              <SelectField
                form={form}
                name="profession"
                type="string"
                placeholder="Professione"
                options={professionsJson}
              />
            </div>
          </div>
          <div className="flex">
            <div className="mr-3 w-64">
              <div>
                <FormLabel>Email</FormLabel>
                <TextField
                  form={form}
                  name="email"
                  type="email"
                  placeholder="Email"
                  validation={{
                    required: { value: true, message: 'Email mancante' },
                  }}
                />
              </div>
            </div>
            <div className="mr-3 w-64">
              <div>
                <FormLabel>Cellulare</FormLabel>
                <TextField form={form} name="phone" type="string" placeholder="Cellulare" />
              </div>
            </div>
            <div className="mr-3 w-64">
              <FormLabel>Data nascita</FormLabel>
              <TextField
                form={form}
                name="birthDate"
                type="date"
                placeholder="Data nascita"
                validation={{
                  required: { value: true, message: 'Data nascita mancante' },
                }}
              />
            </div>
          </div>
          <BirthPlaceSection user={user} form={form} />
        </div>
      </form>
    </div>
  );
};

export default PersonalDetails;
