import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import toast from 'react-hot-toast';
import LoadingSpinner from '../../assets/icons/LoadingSpinner';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa6';
import { useLocalStorage } from '../../utils/UseLocalStorage';

const Table = ({
  header = [],
  sortableCols = [],
  itemsLayout = {},
  fetchFunction,
  queryProps = null,
  perPage = null,
  rowClassFunction = () => '',
  customTable = null,
  headClassName = 'text-white bg-gray-400 border-gray-200',
  children = <></>,
  avoidLoading = false,
  emptyTableMessage = 'Nessun dato presente in tabella',
  updateQueryProps = () => {},
  tableId = '',
}) => {
  const [from, setFrom] = useState(0);
  const [count, setCount] = useState(0);
  const [resource, setResource] = useState([]);
  const [limit, setLimit] = useLocalStorage('defaultTableLength' + tableId, 10);

  perPage = parseInt(perPage || limit);

  const propsCheck = JSON.stringify(queryProps);

  useEffect(() => {
    if (customTable) return;

    const loadData = async () => {
      try {
        if (!avoidLoading) setResource(null);
        const data = await fetchFunction(from, perPage, queryProps);
        setResource(data.resource);
        setCount(data.count);
      } catch (e) {
        toast.error('Errore nel caricamento dei dati');
        console.error(e);
      }
    };

    loadData();
  }, [propsCheck, from, perPage]);

  const SortIcon = ({ isSorted = false, order = 'asc', isSortable = false, className = "inline-block mb-1" }) =>
    isSortable ? (isSorted ? (order === 'asc' ? <FaSortDown className={className} /> : <FaSortUp className={className} />) : <FaSort className={className} />) : null;

  return (
    <>
      <div className="w-full overflow-x-auto border-b">
        <table className="table-auto w-full font-semibold">
          <thead className={`text-xs uppercase border-t border-b ${headClassName}`}>
            <tr className="font-semibold text-left">
              {header.map((text, key) => (
                <th
                  className="first:pl-5 pr-2 last:pr-5 py-3 whitespace-nowrap"
                  onClick={(e) => {
                    if (sortableCols.includes(text)) {
                      updateQueryProps('ordinaPer', text, e);
                      if (queryProps?._sortField === text) {
                        updateQueryProps('ordine', queryProps?.sortOrder === 'desc' ? 'asc' : 'desc', e);
                      }
                    }
                  }}
                  role={sortableCols.includes(text) ? "button" : ''}
                  key={key}
                >
                  {text}{' '}
                  <SortIcon
                    isSorted={queryProps?._sortField === text}
                    order={queryProps?.sortOrder}
                    isSortable={sortableCols.includes(text)}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm divide-y text-gray-600 divide-gray-200">
            {!customTable ? (
              resource === null ? (
                <tr>
                  <td className="text-center py-3" colSpan={header.length}>
                    <LoadingSpinner className="w-5 inline" />
                  </td>
                </tr>
              ) : resource.length <= 0 ? (
                <tr>
                  <td className="text-center font-normal py-16 text-gray-400 text-2xl" colSpan={header.length}>
                    {emptyTableMessage}
                  </td>
                </tr>
              ) : (
                resource.map((row, rowKey) => (
                  <tr className={rowClassFunction(row)} key={rowKey}>
                    {Object.entries(itemsLayout).map(([key, cell]) => (
                      <td className="first:pl-5 pr-2 last:pr-5 py-3 whitespace-nowrap" key={key}>
                        {cell(row[key], row)}
                      </td>
                    ))}
                  </tr>
                ))
              )
            ) : null}
            {children}
          </tbody>
        </table>
      </div>

      {!customTable && (
        <Navigation
          from={parseInt(from) + 1}
          to={Math.min(from + perPage, count)}
          length={count}
          precFunction={() => {
            if (from - perPage >= 0) setFrom(from - perPage);
          }}
          succFunction={() => {
            if (from + perPage < count) setFrom(from + perPage);
          }}
        />
      )}
    </>
  );
};

export default Table;