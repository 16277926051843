import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import AdminPage from '../../../components/Admin/AdminPage';
import VehiclesTable from '../../../components/Vehicles/Vehicles/VehiclesTable';
import { http } from '../../../utils/Utils';
import { MOVOLAB_ROLE_ADMIN } from '../../../utils/Utils';
import { useHistory, useLocation } from 'react-router-dom';
import TableHeader from '../../../components/UI/TableHeader';
import WhiteBox from '../../../components/UI/WhiteBox';
import FilterSelectField from '../../../components/Form/FilterSelectField';
import VehiclesFilterContainer from '../../../components/Vehicles/Vehicles/VehiclesFilterContainer';
import { FaCarOn } from 'react-icons/fa6';
import { getCurrentPartnerCode, UserContext } from '../../../store/UserContext';

const AdminVehicles = () => {
  const history = useHistory();
  const search = useLocation().search;
  const [curPartner, setCurPartner] = useState(null);
  const { data: currentClient } = useContext(UserContext);

  const [vehiclesCount, setVehiclesCount] = useState([]);
  const [from, setFrom] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  const [plateString, setPlateString] = useState('');

  const [rentalLocations, setRentalLocations] = useState([]);
  const [selectedRentalLocation, setSelectedRentalLocation] = useState(
    new URLSearchParams(search).get('puntoNolo'),
  );
  const [models, setModels] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(
    new URLSearchParams(search).get('marca') || undefined,
  );
  const [brands, setBrands] = useState([]);
  const [selectedModel, setSelectedModel] = useState(
    new URLSearchParams(search).get('modello') || undefined,
  );
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(
    new URLSearchParams(search).get('gruppo') || undefined,
  );

  useEffect(async () => {
    setCurPartner(await getCurrentPartnerCode(currentClient?.client));
  }, [currentClient]);

  useEffect(() => {
    fetchVehicles(selectedRentalLocation, 0, 10);
    fetchModels();
    fetchBrands();
    getRentalLocations();
    fetchGroups();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVehicles = async (rentalLocation, skip = 0, limit = 10) => {
    try {
      const response = await http({
        url:
          `/vehicles/vehicle?rentalLocation=${rentalLocation}&skip=${skip}&limit=${limit}` +
          (selectedGroup ? `&group=${selectedGroup}` : '') +
          (selectedBrand ? `&brand=${selectedBrand}` : '') +
          (selectedModel ? `&model=${selectedModel}` : ''),
      });

      setVehicles(response.vehicles);
      setVehiclesCount(response.count);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const filterVehiclesByPlate = async (plate, skip = 0, limit = 10) => {
    try {
      const response = await http({
        url: `/vehicles/vehicle/filterByPlate?plate=${plate}&skip=${skip}&limit=${limit}`,
      });

      setVehicles(response.vehicles);
      setVehiclesCount(response.count);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const searchSubmit = async (data, e) => {
    e.preventDefault();

    setPlateString(data.query);

    if (data.query) {
      const query = data.query.toLowerCase();
      filterVehiclesByPlate(query);
    } else {
      return fetchVehicles('', 0, 10);
    }
  };

  const precFunction = () => {
    if (from - 10 < 0) return;
    if (plateString) {
      filterVehiclesByPlate(plateString, from - 10, 10);
    } else {
      fetchVehicles(selectedRentalLocation, from - 10, 10);
    }
    setFrom(from - 10);
  };

  const succFunction = () => {
    if (from + 10 > vehiclesCount) return;
    if (plateString) {
      filterVehiclesByPlate(plateString, from + 10, 10);
    } else {
      fetchVehicles(selectedRentalLocation, from + 10, 10);
    }
    setFrom(from + 10);
  };

  useEffect(() => {
    fetchVehicles(selectedRentalLocation);
  }, [selectedModel, selectedBrand, selectedRentalLocation, selectedGroup]);

  const getRentalLocations = async () => {
    try {
      const response = await http({ url: `/clients/rentalLocation` });
      setRentalLocations(
        response.rentalLocations.map((rentalLocation) => {
          return {
            value: rentalLocation._id,
            label: `${rentalLocation.name} - ${rentalLocation.city}`,
          };
        }),
      );
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const fetchBrands = async () => {
    try {
      const response = await http({ url: '/vehicles/brand' });
      setBrands(
        response.brands.map((brand) => ({
          value: brand._id,
          label: brand.brandName,
        })),
      );
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const fetchModels = async (brandId) => {
    try {
      if (!brandId) return;
      const response = await http({ url: `/vehicles/model/byBrand/${brandId}` });
      if (!response.model) return;
      setModels(
        response.model.map((model) => {
          return { value: model._id, label: model.modelName };
        }),
      );
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await http({ url: '/groups' });
      setGroups(
        response.groups.map((group) => ({
          value: group?._id,
          label: `${group?.mnemonic} - ${group?.description}`,
        })),
      );
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <AdminPage canAccess={[MOVOLAB_ROLE_ADMIN]} hasBox={false}>
      <WhiteBox>
        <div className="flex justify-between items-center gap-2 mr-6">
          <TableHeader
            tableName={'Veicoli'}
            buttons={[
              {
                function: () => {
                  window.open(curPartner?.partnerUrl, '_blank');
                },
                label: 'Acquista veicoli',
                svgIco: <FaCarOn className="inline mb-1" />,
                hidden: !curPartner?.partnerUrl,
              },
            ]}
            length={vehiclesCount}
          />

          <div className="flex gap-2">
            <FilterSelectField
              onChange={(e) => setSelectedRentalLocation(e.target.value)}
              emptyOption={{ label: 'Tutti i Punti Nolo' }}
              defaultValue={rentalLocations.find(
                (rental) => rental.value === selectedRentalLocation,
              )}
              options={rentalLocations}
            />
            <FilterSelectField
              onChange={(e) => {
                setSelectedGroup(e.target.value);
              }}
              emptyOption={{ label: 'Tutti i gruppi' }}
              defaultValue={groups.find((group) => group.value === selectedGroup)}
              options={groups}
            />
            <FilterSelectField
              onChange={(e) => {
                setSelectedBrand(e.target.value);
                fetchModels(e.target.value);
              }}
              emptyOption={{ label: 'Tutte le marche' }}
              defaultValue={brands.find((brand) => brand.value === selectedBrand)}
              options={brands}
            />
            {selectedBrand && (
              <FilterSelectField
                onChange={(e) => setSelectedModel(e.target.value)}
                emptyOption={{ label: 'Tutti i modelli' }}
                defaultValue={models.find((model) => model.value === selectedModel)}
                options={models}
              />
            )}
            <div className="flex flex-1 justify-end">
              <VehiclesFilterContainer onSubmit={searchSubmit} />
            </div>
          </div>
        </div>
        {vehicles.length === 0 ? (
          <div className="flex justify-center items-center h-64">
            <div className="text-gray-400 text-2xl">Nessun veicolo trovato</div>
          </div>
        ) : (
          <VehiclesTable
            elements={vehicles}
            from={from}
            count={vehiclesCount}
            precFunction={precFunction}
            succFunction={succFunction}
          />
        )}
      </WhiteBox>
    </AdminPage>
  );
};

export default AdminVehicles;
