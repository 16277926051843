import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import SettingsPage from '../../../components/Settings/SettingsPage';
import FormLabel from '../../../components/UI/FormLabel';
import { CLIENT_ROLE_ADMIN } from '../../../utils/Utils';
import { http } from '../../../utils/Utils';
import { SelectField } from '../../../components/Form/SelectField';
import { TextField } from '../../../components/Form/TextField';
import { useForm } from 'react-hook-form';
import CardsHeader from '../../../components/UI/CardsHeader';
import WhiteBox from '../../../components/UI/WhiteBox';
import ColorPicker from '../../../components/Form/ColorPicker';

const defaultConfiguration = {
  vehicleDisplayMode: 'model',
  refineSearch: 'aperto',
  doorField: 'visibile',
  seatsField: 'visibile',
  fuelField: 'visibile',
  transmissionField: 'visibile',
  priceRangeField: 'visibile',
  promoCodeField: 'visibile',
  ageField: 'visibile',
  nationalityFieldSearch: 'visibile',
  nationalityField: 'visibile',
  genderField: 'visibile',
  birthdateField: 'visibile'
};

const WidgetItem = () => {
  const history = useHistory();
  const params = useParams();
  const form = useForm();
  const [workflowsPayNow, setWorkflowsPayNow] = useState([]);
  const [workflowsPayLater, setWorkflowsPayLater] = useState([]);
  const [widgetId, setWidgetId] = useState('');
  useEffect(() => {
    fetchWorkflows();
  }, []); // eslint-disable-line
  const mode = params.id && params.id !== 'crea' ? 'edit' : 'create';
  useEffect(() => {
    fetchWidgetDetails();
  }, [mode === 'edit']); // eslint-disable-line
  const fetchWidgetDetails = async () => {
    try {
      if (params.id !== 'crea') {
        const widget = await http({
          url: `/widgets/${params.id}`,
        });
        form.setValue('name', widget.name);
        form.setValue('workflowPayNow', widget.workflowPayNow?._id);
        form.setValue('workflowPayLater', widget.workflowPayLater?._id);

         form.setValue('configuration', {
        ...defaultConfiguration,
        ...(widget.configuration || {})
        });

        setWidgetId(widget.widgetId);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const fetchWorkflows = async () => {
    try {
      const response = await http({ url: `/workflow/active` });
      const responseWorkflowsPayNow = [];
      const responseWorkflowsPayLater = [];
      response.workflows.map((workflow) => {
        if (
          workflow?.administration?.prepaid &&
          workflow?.administration?.prepaidReservation === 100
        ) {
          responseWorkflowsPayNow.push(workflow);
        } else {
          responseWorkflowsPayLater.push(workflow);
        }
      });
      setWorkflowsPayNow(responseWorkflowsPayNow);
      setWorkflowsPayLater(responseWorkflowsPayLater);
    } catch (err) {
      console.error(err);
    }
  };
  const mapNodeEnv = (env) => {
    if (env === 'development') return 'dist-dev';
    else if (env === 'demo') return 'dist-demo';
    else if (env === 'production') return 'dist-prod';
  };
  const onSubmit = async (data) => {
    try {
      data.workflowPayNow = data.workflowPayNow || null;
      data.workflowPayLater = data.workflowPayLater || null;

      data.configuration = {
        ...defaultConfiguration,
        ...(data.configuration || {})
      };

      if (mode === 'create') {
        const response = await http({
          method: 'POST',
          url: '/widgets',
          form: data,
        });
        toast.success('Widget creato con successo');
        history.push(`/settings/widget/${response?._id}`);
      } else if (mode === 'edit') {
        await http({
          method: 'PUT',
          url: `/widgets/${params.id}`,
          form: data,
        });
        toast.success('Widget aggiornato');
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const privacyLink = form.watch('configuration.privacyLink');
  const termsLink = form.watch('configuration.termsLink');
  const widgetLang = form.watch('configuration.widgetLang');
  const thankYouPage = form.watch('configuration.thankYouPage');
  
  return (
    <SettingsPage canAccess={CLIENT_ROLE_ADMIN} hasBox={false}>
      <CardsHeader
        title={`${mode === 'edit' ? 'Modifica' : 'Aggiungi'} widget`}
        buttons={[
          {
            btnStyle: 'lightSlateTransparent',
            children: '« Indietro',
            onClick: () => history.goBack(),
          },
          {
            children: 'Salva widget',
            form: 'widgetForm',
          },
        ]}
      />
      <WhiteBox className="mt-0 p-4">
        <div className="p-4 w-full">
          <div>
            {mode === 'edit' && (
              <div className="">
                <div className="font-medium text-gray-600 text-lg pb-2">
                  <span>Widget ID: {widgetId}</span>
                </div>
                <div className="flex flex-wrap gap-x-6">
                  <div>
                    <form onSubmit={form.handleSubmit(onSubmit)} id="widgetForm">
                      <div className="w-96">
                        <FormLabel>Nome Identificativo</FormLabel>
                        <TextField name="name" form={form} placeholder="Nome Identificativo" />
                      </div>
                      <div className="w-96 mt-3">
                        <FormLabel>Flusso Paga Ora</FormLabel>
                        <div className="text-xs text-gray-800">
                          Questo è il flusso che viene usato dal widget per le prenotazioni che
                          vengono pagate online
                        </div>
                        <SelectField
                          name="workflowPayNow"
                          form={form}
                          options={
                            [{ value: '', label: "Nessun flusso" }, ...workflowsPayNow.map(({ _id, name }) => ({ value: _id, label: name }))]
                          }
                        />
                      </div>
                      <div className="w-96 mt-3">
                        <FormLabel>Flusso Paga Dopo</FormLabel>
                        <div className="text-xs text-gray-800">
                          Questo è il flusso che viene usato dal widget per le prenotazioni che
                          vengono pagate a fine Movo
                        </div>
                        <SelectField
                          name="workflowPayLater"
                          form={form}
                          options={
                            [{ value: '', label: "Nessun flusso" }, ...workflowsPayLater.map(({ _id, name }) => ({ value: _id, label: name }))]
                          }
                        />
                      </div>
                      <div className="w-96 mt-3">
                        <FormLabel>Dominio Autorizzato</FormLabel>
                        <div className="text-xs text-gray-800">
                          Inserisci il dominio in cui il widget può essere utilizzato (es. https://example.com)
                        </div>
                        <TextField
                          name="configuration.authorizedDomain"
                          form={form}
                          placeholder="Dominio autorizzato"
                        />
                      </div>
                    </form>
                  </div>
                  <div>
                    <TextField
                      className="w-96 mb-3"
                      name="configuration.privacyLink"
                      form={form}
                      placeholder="Url privacy policy"
                      label="Url privacy policy"
                    />
                    <TextField
                      className="w-96 mb-3"
                      name="configuration.termsLink"
                      form={form}
                      placeholder="Url termini e condizioni"
                      label="Url termini e condizioni"
                    />
                    <TextField
                      className="w-96 mb-3"
                      name="configuration.thankYouPage"
                      form={form}
                      label="Url pagina redirect dopo prenotazione"
                      placeholder="Url thank you page"
                    />
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.widgetLang"
                      form={form}
                      placeholder="Lingua widget"
                      options={[
                        { value: 'it-IT', label: 'Italiano' },
                        { value: 'en-EN', label: 'Inglese' },
                        { value: 'fr-FR', label: 'Francese' },
                        { value: 'es-ES', label: 'Spagnolo' },
                        { value: 'de-DE', label: 'Tedesco' },
                      ]}
                      label="Lingua widget"
                    />
                    <FormLabel>Colore widget primario</FormLabel>
                    <ColorPicker
                      className="w-96 mb-3"
                      name="configuration.primaryColor"
                      form={form}
                      placeholder="Colore primario"
                    />
                    <FormLabel>Colore widget secondario</FormLabel>
                    <ColorPicker
                      className="w-96 mb-3"
                      name="configuration.secondaryColor"
                      form={form}
                      placeholder="Colore secondario"
                    />
                  </div>
                  <div>
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.ageField"
                      form={form}
                      placeholder="Seleziona visibilità"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo età"
                    />
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.nationalityFieldSearch"
                      form={form}
                      placeholder="Seleziona visibilità"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo nazionalità (ricerca)"
                    />
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.nationalityField"
                      form={form}
                      placeholder="Seleziona visibilità"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo nazionalità"
                    />
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.genderField"
                      form={form}
                      placeholder="Seleziona visibilità"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo genere"
                    />
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.birthdateField"
                      form={form}
                      placeholder="Seleziona visibilità"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo data nascita"
                    />
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.promoCodeField"
                      form={form}
                      placeholder="Seleziona visibilità promocode"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                        { label: 'Nascosto in ricerca', value: 'nascosto-ricerca' },
                      ]}
                      label="Campo Codice Promo"
                    />
                  </div>
                </div>
                <div className='mt-4 flex flex-wrap gap-x-6'>
                  <div>
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.vehicleDisplayMode"
                      form={form}
                      placeholder="Seleziona modalità"
                      options={[
                        { label: 'Per Modello', value: 'model' },
                        { label: 'Per Gruppo', value: 'group' },
                      ]}
                      label="Visualizzazione veicoli"
                    />
                  </div>
                  <div>
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.refineSearch"
                      form={form}
                      placeholder="Seleziona opzione"
                      options={[
                        { label: 'Aperto', value: 'aperto' },
                        { label: 'Chiuso', value: 'chiuso' },
                      ]}
                      label="Affina ricerca"
                    />
                  </div>
                  <div>
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.doorField"
                      form={form}
                      placeholder="Seleziona opzione"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo porte"
                    />
                    
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.seatsField"
                      form={form}
                      placeholder="Seleziona opzione"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo posti"
                    />
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.fuelField"
                      form={form}
                      placeholder="Seleziona opzione"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo alimentazione"
                    />
                    
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.transmissionField"
                      form={form}
                      placeholder="Seleziona opzione"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo trasmissione"
                    />
                    <SelectField
                      className="w-96 mb-3"
                      name="configuration.priceRangeField"
                      form={form}
                      placeholder="Seleziona opzione"
                      options={[
                        { label: 'Visibile', value: 'visibile' },
                        { label: 'Nascosto', value: 'nascosto' },
                      ]}
                      label="Campo fascia di prezzo"
                    />
                  </div>
                </div>
                <div className="embed-widget-section bg-gray-100 p-4 rounded-md shadow-md mt-4">
                  <h3 className="text-md font-semibold mb-2">
                    Inserisci questo Widget all'interno del tuo sito.
                  </h3>
                  <p className="mb-2">
                    Per inserire questo Widget all'interno del tuo sito, chiedi ai tuoi developer di
                    inserire il seguente codice:
                  </p>
                  <pre className="bg-gray-200 p-2 rounded-md text-xs overflow-x-auto">
                    <code>{`<div>
    <script defer src="https://movolab.it/widget/dist/main.js"></script>
    <div id="movolab-app" ${termsLink ? `\n      data-privacy-policy="${termsLink}"` : ''} ${privacyLink ? `\n      data-terms-and-conditions="${privacyLink}"` : ''
                      } ${widgetLang ? `\n      data-lang="${widgetLang}"` : ''} ${thankYouPage ? `\n      data-thankyou-page="${thankYouPage}"` : ''}
      data-widget-id="${widgetId}">
    </div>
</div>`}</code>
                  </pre>
                </div>
              </div>
            )}
            {mode === 'create' && (
              <div className="">
                <form onSubmit={form.handleSubmit(onSubmit)} id="widgetForm" className="pl-2">
                  <div className="w-96 mr-3">
                    <FormLabel>Nome Identificativo</FormLabel>
                    <TextField name="name" form={form} placeholder="Nome Identificativo" />
                  </div>
                  <div className="w-96 mr-3 mt-3">
                    <FormLabel>Flusso Paga Ora</FormLabel>
                    <div className="text-xs text-gray-800">
                      Questo è il flusso che viene usato dal widget per le prenotazioni che vengono
                      pagate online
                    </div>
                    <SelectField
                      name="workflowPayNow"
                      form={form}
                      options={
                        workflowsPayNow.map((workflow) => ({
                          value: workflow._id,
                          label: workflow.name,
                        })) || []
                      }
                      placeholder="Seleziona Flusso"
                    />
                  </div>
                  <div className="w-96 mr-3 mt-3">
                    <FormLabel>Flusso Paga Dopo</FormLabel>
                    <div className="text-xs text-gray-800">
                      Questo è il flusso che viene usato dal widget per le prenotazioni che vengono
                      pagate a fine Movo
                    </div>
                    <SelectField
                      name="workflowPayLater"
                      form={form}
                      options={
                        workflowsPayLater.map((workflow) => ({
                          value: workflow._id,
                          label: workflow.name,
                        })) || []
                      }
                      placeholder="Seleziona Flusso"
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </WhiteBox>
    </SettingsPage>
  );
};

export default WidgetItem;