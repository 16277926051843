import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Page from '../../../components/Dashboard/Page';
import ReservationsTable from '../../../components/Reservations/ReservationsTable';
import { http } from '../../../utils/Utils';
import { CLIENT_ROLE_ADMIN, CLIENT_ROLE_OPERATOR } from '../../../utils/Utils';
import TableHeader from '../../../components/UI/TableHeader';
import { useHistory, useLocation } from 'react-router-dom';
import WhiteBox from '../../../components/UI/WhiteBox';
import PlusOutlineCircle from '../../../assets/icons/PlusOutlineCircle';
import FilterSelectField from '../../../components/Form/FilterSelectField';
import Accordion from '../../../components/UI/Accordion';
import FilterTextField, { FilterSearchButton, FilterText } from '../../../components/Form/FilterTextField';
import { FaAngleDown } from 'react-icons/fa';
import { useLocalStorage } from '../../../utils/UseLocalStorage';
import TableLengthSelector from '../../../components/UI/TableLengthSelector';

const Reservations = () => {
  const history = useHistory();
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);
  const [reservationsCount, setReservationsCount] = useState([]);
  const [rentalLocations, setRentalLocations] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [filtersOpen, openFilters] = useLocalStorage('reservationFilters', false);
  const [limit, setLimit] = useLocalStorage('defaultTableLengthreservationsTable', 10);

  const reservationStates = [
    { value: 'draft', label: 'Bozza' },
    { value: 'aperto', label: 'Aperto' },
    { value: 'attivo', label: 'Attivo' },
    { value: 'chiuso', label: 'Chiuso' },
    { value: 'fatturato', label: 'Fatturato' },
    { value: 'annullato', label: 'Annullato' },
    { value: 'stornato', label: 'Stornato' },
    { value: 'no show', label: 'No Show' },
  ];

  const updateQuery = (key, value) => {
    if (value) {
      urlParams.set(key, value);
    } else {
      urlParams.delete(key);
    }
    history.push(`/dashboard/prenotazioni?${urlParams.toString()}`);
  };

  const sortableFieldMap = {
    'Codice': 'code',
    'Stato': 'state',
    'Conducente': 'driverFullName',
    'Inizio': 'pickUpDate',
    'Fine': 'dropOffDate',
    'Creazione': 'createdAt',
    'Prezzo': 'price'
  };

  const tableFilters = {
    state: urlParams.get('stato'),
    pickUpLocation: urlParams.get('puntoNolo'),
    vehicle: urlParams.get('veicolo'),
    specificPickUpDate: urlParams.get('dataRitiro'),
    search: urlParams.get('search'),
    sortOrder: urlParams.get('ordine'),
    sortField: sortableFieldMap?.[urlParams.get('ordinaPer')] || null,
    _sortField: urlParams.get('ordinaPer'),
  };

  useEffect(() => {
    getRentalLocations();
    getVehicles();
  }, []);

  const getRentalLocations = async () => {
    try {
      const response = await http({ url: `/clients/rentalLocation` });
      setRentalLocations(
        response.rentalLocations.map((rentalLocation) => ({
          value: rentalLocation._id,
          label: `${rentalLocation.name} - ${rentalLocation.city}`,
        }))
      );
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const getVehicles = async () => {
    try {
      const response = await http({ url: `/vehicles/vehicle` });
      setVehicles(response.vehicles);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <Page canAccess={[CLIENT_ROLE_ADMIN, CLIENT_ROLE_OPERATOR]}>
      <WhiteBox>
        <TableHeader
          tableName="Prenotazioni"
          buttons={[
            {
              function: () => history.push('/dashboard/prenotazioni/crea'),
              label: 'Nuova prenotazione',
              svgIco: <PlusOutlineCircle />,
            }
          ]}
          length={reservationsCount}
        >
          <div className="flex justify-end gap-2 flex-1 items-center flex-wrap">
            <button className="text-xs opacity-70 hover:opacity-100" onClick={() => openFilters(!filtersOpen)}>
              {filtersOpen ? 'Nascondi filtri' : 'Più filtri'}{' '}
              <FaAngleDown className={`inline mb-1 ${filtersOpen && 'transform rotate-180'}`} />
            </button>
            <FilterTextField
              placeholder="..."
              buttonRight={<FilterSearchButton onClick={() => updateQuery('search', searchQuery)} />}
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </div>
        </TableHeader>

        <Accordion innerClassName="flex flex-wrap justify-between gap-2 pb-6 px-6 !-mt-3" triggerOpen={filtersOpen} hideButtons>
          <div className="mr-auto">
            <TableLengthSelector value={limit} onChange={setLimit} />
          </div>
          <div className="flex flex-wrap gap-1 ml-auto">
            <FilterSelectField
              onChange={(e) => updateQuery('stato', e.target.value)}
              emptyOption={{ label: 'Tutti gli Stati' }}
              defaultValue={reservationStates.find((state) => state.value === tableFilters.state)}
              options={reservationStates}
              className="w-full md:w-36"
            />
            <FilterSelectField
              onChange={(e) => updateQuery('puntoNolo', e.target.value)}
              emptyOption={{ label: 'Tutti i Punti Nolo' }}
              defaultValue={rentalLocations.find((r) => r.value === tableFilters.rentalLocation)}
              options={rentalLocations}
              className="w-full md:w-36"
            />
            <FilterSelectField
              onChange={(e) => updateQuery('veicolo', e.target.value)}
              emptyOption={{ label: 'Tutti i veicoli' }}
              defaultValue={vehicles.find((v) => v.value === tableFilters.vehicle)}
              options={vehicles.map((v) => ({ value: v._id, label: v.plate }))}
              className="w-full md:w-36"
            />
            <FilterTextField
              type="date"
              onChange={(e) => updateQuery('dataRitiro', e.target.value)}
              value={tableFilters.specificPickUpDate}
              textLeft={<FilterText text="Ritiro il" />}
              className="w-full md:w-52"
            />
          </div>
        </Accordion>

        <ReservationsTable
          queryProps={tableFilters}
          updateReservationsCount={setReservationsCount}
          updateQueryProps={updateQuery}
          sortableCols={Object.keys(sortableFieldMap)}
          tableId="reservationsTable"
          addRowNumberSelector={false}
          perPage={limit}
        />
      </WhiteBox>
    </Page>
  );
};

export default Reservations;