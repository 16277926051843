import React, { useState } from 'react';
import ElementLabel from '../UI/ElementLabel';
import DamageModal from './DamageModal';
import { FaPen } from 'react-icons/fa';
import Modal from '../UI/Modal';
import { FaDownload } from 'react-icons/fa6';

const vehiclePartMap = {
  body: 'Carrozzeria',
  wheel: 'Ruota',
  windshield: 'Parabrezza',
  'rear windowshield': 'Lunotto posteriore',
  glass: 'Vetro',
  mirror: 'Specchietto',
  light: 'Fanale',
  seat: 'Sedile',
  dashboard: 'Cruscotto',
  panel: 'Plancia',
  'steering wheel': 'Volante',
  ceiling: 'Cielo interno',
  other: 'Altro',
};

export const mapDamageVehiclePart = (vehiclePart) => vehiclePartMap?.[vehiclePart] || 'Altro';

const damageTypeMap = {
  scratch: 'Graffio',
  dent: 'Ammaccatura',
  crack: 'Crepa',
  break: 'Rottura',
  hole: 'Foro',
  tear: 'Lacerazione',
  other: 'Altro',
};

export const mapDamageType = (damageType) => damageTypeMap?.[damageType] || 'Danno';

export const mapDamageStatusLabel = (status) => {
  if (status === 'open') {
    return 'Aperto';
  }
  if (status === 'closed') {
    return 'Chiuso';
  }
};

export const mapDamageLevel = (level) => {
  if (level === 'low') {
    return 'Lieve';
  }
  if (level === 'medium') {
    return 'Medio';
  }
  if (level === 'high') {
    return 'Grave';
  }
};

export const mapDamageStatusColor = (status) => {
  if (status === 'open') {
    return 'bg-yellow-600';
  }
  if (status === 'closed') {
    return 'bg-green-600';
  }
};

export const mapDamageLevelColor = (lvl) =>
  lvl === 'low' ? 'bg-green-700' : lvl === 'medium' ? 'bg-yellow-700' : 'bg-red-700';

const Damage = ({ rentId, vehicleId, groupId, damage, phase, closeModal }) => {
  const [showDamageModal, setShowDamageModal] = useState(false);
  const [showBigPhoto, setBigPhoto] = useState(false);

  const closeDamageModal = () => {
    setShowDamageModal(false);
    closeModal();
  };

  return (
    <div key={damage._id} className="bg-gray-100 rounded-lg mt-3 p-3">
      <div className="flex flex-col-reverse md:flex-row flex-wrap md:flex-nowrap md:content-end md:gap-4 gap-1">
        {damage.imageUrl ? (
          <div
            className="bg-cover mt-2 md:mt-0 bg-center bg-no-repeat h-32 w-full md:w-52 md:my-[-0.25em] md:ml-[-0.25em] rounded-b md:rounded-l md:rounded-r-none hover:opacity-80"
            role="button"
            onClick={() => setBigPhoto(damage.imageUrl)}
            style={{ backgroundImage: `url(${damage.imageUrl})` }}
          />
        ) : (
          ''
        )}

        <div className="w-full md:w-auto flex-1">
          <div className="text-md font-semibold py-1">
            {mapDamageType(damage.damageType)} su {mapDamageVehiclePart(damage.vehiclePart ?? 'other')}
            <FaPen
              className="inline ml-2 mb-1 cursor-pointer hover:opacity-70"
              onClick={() => setShowDamageModal(true)}
              title="Modifica"
            />
            {damage.damageLevel !== undefined && (
              <span className="md:ml-2">
                <ElementLabel className="ml-3" bgColor={mapDamageLevelColor(damage.damageLevel)}>
                  {mapDamageLevel(damage.damageLevel)}
                </ElementLabel>
              </span>
            )}
          </div>
          <div className="text-sm mt-1 break-all">{damage.description}</div>
          <h6 className="text-sm mt-2 text-gray-600">
            Inserito il: {new Date(damage.date).toLocaleDateString()}
          </h6>
        </div>
        <div className="w-full md:w-auto">
          <div className="md:mb-3">
            {damage?.status !== undefined ? (
              <ElementLabel bgColor={mapDamageStatusColor(damage?.status)}>
                {mapDamageStatusLabel(damage?.status)}
              </ElementLabel>
            ) : null}
          </div>
        </div>
      </div>
      {showDamageModal ? (
        <DamageModal
          vehicleId={vehicleId}
          damage={damage}
          groupId={damage.groupId}
          phase={phase}
          closeModal={closeDamageModal}
        />
      ) : null}

      <Modal
        isVisible={showBigPhoto}
        size="lg"
        onClose={(e) => {
          e.preventDefault();
          setBigPhoto(false);
        }}
        headerChildren={<>Foto del danno <a href={showBigPhoto} title="Scarica"><FaDownload className="inline hover:opacity-80" /></a></>}
      >
        <img src={showBigPhoto} className="w-full" alt="Foto del danno" />
      </Modal>
    </div>
  );
};

export default Damage;
