import React from 'react';
import { TextField } from './TextField';
import { HiMiniMagnifyingGlass } from "react-icons/hi2";

export const FilterButton = ({ children, ...props }) => (
  <button
    type="button"
    className="rounded-r-lg border-r border-t border-b !border-slate-300 whitespace-nowrap text-sm sm:w-auto xs:w-auto enabled:hover:bg-slate-200 disabled:text-slate-400 disabled:cursor-not-allowed sm:mb-0 px-3 py-1"
    {...props}
  >
    {children}
  </button>
);
export const FilterText = ({ text = '', children,  ...props }) => (
  <span
    className="rounded-l-lg border-l border-t border-b !border-slate-300 whitespace-nowrap text-sm sm:w-auto xs:w-auto enabled:hover:bg-slate-200 disabled:text-slate-400 disabled:cursor-not-allowed sm:mb-0 px-3 py-1"
    {...props}
  >
    {children} {text}
  </span>
);

export const FilterSearchButton = ({ children = 'Cerca', ...props }) => (
  <FilterButton {...props}>
    <HiMiniMagnifyingGlass className="inline-block text-base mb-1" /> {children}
  </FilterButton>
);

const FilterTextField = ({
  className = 'mb-0',
  ...props
}) => (
  <TextField
    className={className}
    inputClassName={`text-sm !border-slate-300 ${props?.buttonRight ? 'rounded-l-lg rounded-r-none' : props?.textLeft ? 'rounded-r-lg rounded-l-none' : 'rounded-lg'}`}
    inputGroupClassName="flex"
    {...props}
  />
);

export default FilterTextField;
