import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { http } from '../../utils/Utils';

import { TextField } from '../Form/TextField';
import Button from '../UI/buttons/Button';
import { TextareaField } from '../Form/TextareaField';
import ToggleSwitch from '../UI/ToggleSwitch';
import moment from 'moment';
import { SelectField } from '../Form/SelectField';

const UpdatePromoCodeGeneral = ({ isAdmin = false }) => {
  const form = useForm();
  const params = useParams();
  const history = useHistory();
  const mode = params.id ? 'edit' : 'create';
  const [clients, setClients] = useState([]);

  useEffect(() => {
    fetchPromoCode();
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const fetchPromoCode = async () => {
    try {
      if (mode === 'edit') {
        const response = await http({ url: `/pricing/promoCode/${params.id}` });

        Object.entries(response).forEach(([k, v]) => form.setValue(k, v));

        form.setValue('validFrom', moment(response.validFrom).format().slice(0, 16));
        form.setValue('validUntil', moment(response.validUntil).format().slice(0, 16));
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const onSubmit = async (data) => {
    try {
      if (mode === 'create') {
        //eslint-disable-next-line
        await http({
          method: 'POST',
          url: '/pricing/promoCode',
          form: data,
        });
        toast.success('Codice Promo creato');
        history.push(isAdmin ? `/admin/codicipromo` : `/settings/codicipromo`);
      } else if (mode === 'edit') {
        await http({
          method: 'PUT',
          url: `/pricing/promoCode/${params.id}`,
          form: data,
        });
        toast.success('Codice Promo aggiornato');
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const fetchClients = async () => {
    try {
      const response = await http({ url: `/clients/client/all` });

      setClients(response.result);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="p-2" id="promoForm">
      {isAdmin ? <h3 className="text-lg font-semibold">
        {mode === 'edit' ? 'Aggiorna Codice Promo' : 'Crea Codice Promo'}
      </h3> : null}
      <fieldset disabled={form.formState.isSubmitting} className="space-y-0">
        <div className="flex flex-wrap md:flex-nowrap gap-x-4">
          <TextField
            form={form}
            name="code"
            type="text"
            placeholder="Codice"
            label="Codice"
            className="max-w-72 w-full"
            validation={{
              required: { value: true, message: 'Inserisci il codice' },
            }}
          />
          <div className="md:mt-8">
            <ToggleSwitch
              switches={[
                {
                  label: 'Attivo',
                  selected: form.watch('active'),
                  onClick: () => form.setValue('active', 1),
                },
                {
                  label: 'Disattivo',
                  selected: !form.watch('active'),
                  onClick: () => form.setValue('active', 0),
                },
              ]}
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-x-4">
          <TextField
            form={form}
            name="validFrom"
            type="datetime-local"
            placeholder="Valido da"
            label="Valido da"
            className="max-w-72 w-full"
            validation={{
              required: { value: true, message: 'Inserisci validità' },
            }}
          />
          <TextField
            form={form}
            name="validUntil"
            type="datetime-local"
            placeholder="Valido a"
            label="Valido a"
            className="max-w-72 w-full"
            validation={{
              required: { value: true, message: 'Inserisci validità' },
            }}
          />
          <TextField
            form={form}
            name="maxUses"
            type="number"
            placeholder="Utilizzi massimi"
            label="Utilizzi massimi"
            className="max-w-72 w-full"
            validation={{
              required: { value: true, message: 'Inserisci numero massimo di utilizzi' },
            }}
          />
          <TextField
            form={form}
            name="uses"
            type="number"
            placeholder="Utilizzi totali"
            label="Utilizzi totali"
            className="max-w-72 w-full"
            disabled={!isAdmin}
          />
          {isAdmin ? <SelectField
            form={form}
            name="client"
            className="max-w-72 w-full"
            label="Assegna a cliente"
            placeholder="Cliente..."
            options={clients.map(c => ({ value: c._id, label: c.ragioneSociale }))}
            validation={{
              required: { value: true, message: 'Il codice promo deve essere assegnato ad un cliente' },
            }}
          /> : null}
        </div>

        <div className="flex flex-wrap gap-x-4">
          <SelectField
            form={form}
            name="discountType"
            className="max-w-72 w-full"
            label="Tipo di sconto"
            placeholder="Tipo di sconto..."
            options={[{ value: 'none', label: 'Nessuno' }, { value: 'percentage', label: 'Percentuale' }, { value: 'amount', label: 'Importo fisso' }]}
            validation={{
              required: { value: true, message: 'Selezionare il tipo di sconto' },
            }}
          />

          {form.watch('discountType') === 'amount' ? <TextField
            form={form}
            name="discountAmount"
            type="number-noformat"
            placeholder="Valore dello sconto"
            label="Valore dello sconto"
            className="max-w-72 w-full"
            step=".01"
            validation={{
              required: { value: form.watch('discountType') === 'amount', message: 'Selezionare l\'importo' },
            }}
            min={0}
            max={10000}
            iconRight={<strong className="inline-block mt-1 font-semibold">&euro;</strong>}
          /> : null}

          {form.watch('discountType') === 'percentage' ? <TextField
            form={form}
            name="discountPercentage"
            type="number"
            placeholder="Percentuale di sconto"
            label="Percentuale di sconto"
            className="max-w-72 w-full"
            validation={{
              required: { value: form.watch('discountType') === 'percentage', message: 'Selezionare la percentuale di sconto' },
            }}
            min={0}
            max={100}
            iconRight={<strong className="inline-block mt-1 font-boldi">%</strong>}
          /> : null}

          <TextField
            form={form}
            name="minimumPurchaseAmount"
            type="number-noformat"
            placeholder="Importo minimo"
            step=".01"
            label="Importo minimo applicabilità"
            className="max-w-72 w-full"
            min={0}
            max={10000}
            iconRight={<strong className="inline-block mt-1 font-semibold">&euro;</strong>}
          />
        </div>

        <TextareaField
          form={form}
          name="description"
          type="text"
          placeholder="Descrizione"
          label="Descrizione"
          className="w-full"
        />

        {isAdmin ? <div className="pt-4">
          <Button>
            {mode === 'edit' ? 'Aggiorna Codice Promo' : 'Crea Codice Promo'}
          </Button>
        </div> : null}
      </fieldset>
    </form>
  );
};

export default UpdatePromoCodeGeneral;
