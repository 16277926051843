import React from 'react';
import { MOVOLAB_ROLE_ADMIN } from '../../../utils/Utils';
import AdminPage from '../../../components/Admin/AdminPage';
import UpdateVehicle from '../../../components/Vehicles/Vehicles/UpdateVehicle';

const ExistingVehicle = (vehicle) => {
  return (
    <AdminPage canAccess={[MOVOLAB_ROLE_ADMIN]} hasBox={false}>
      <UpdateVehicle setShowUpdateVehicle={() => {}} />
    </AdminPage>
  );
};

export default ExistingVehicle;
