import React, { useEffect, useState } from 'react';
import Page from '../../../components/Dashboard/Page';
import RentsTable from '../../../components/Rents/RentsTable';
import { http } from '../../../utils/Utils';
import { CLIENT_ROLE_ADMIN, CLIENT_ROLE_OPERATOR } from '../../../utils/Utils';
import TableHeader from '../../../components/UI/TableHeader';
import { useHistory, useLocation } from 'react-router-dom';
import WhiteBox from '../../../components/UI/WhiteBox';
import PlusOutlineCircle from '../../../assets/icons/PlusOutlineCircle';
import FilterSelectField from '../../../components/Form/FilterSelectField';
import Accordion from '../../../components/UI/Accordion';
import FilterTextField, { FilterSearchButton, FilterText } from '../../../components/Form/FilterTextField';
import { useLocalStorage } from '../../../utils/UseLocalStorage';
import { FaAngleDown } from 'react-icons/fa';
import TableLengthSelector from '../../../components/UI/TableLengthSelector';

const Rents = () => {
  const history = useHistory();
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);

  const [rentsCount, setRentsCount] = useState([]);
  const [rentalLocations, setRentalLocations] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [filtersOpen, openFilters] = useLocalStorage('rentFilters', false);
  const [limit, setLimit] = useLocalStorage('defaultTableLengthrentsTable', 10);

  const rentStates = [
    { value: 'draft', label: 'Bozza' },
    { value: 'aperto', label: 'Aperto' },
    { value: 'attivo', label: 'Attivo' },
    { value: 'chiuso', label: 'Chiuso' },
    { value: 'fatturato', label: 'Fatturato' },
    { value: 'parz fatturato', label: 'Fatturato parziale' },
    { value: 'incassato', label: 'Incassato' },
    { value: 'parz incassato', label: 'Incassato parziale' },
    { value: 'annullato', label: 'Annullato' },
    { value: 'stornato', label: 'Stornato' },
  ];

  const updateQuery = (key, value) => {
    if (value) {
      urlParams.set(key, value);
    } else {
      urlParams.delete(key);
    }
    history.push(`/dashboard/movimenti?${urlParams.toString()}`);
  };

  const sortableFieldMap = {
    'Codice': 'code',
    'Stato': 'state',
    'Inizio': 'pickUpDate',
    'Fine': 'expectedDropOffDate',
    'Creazione': 'createdAt',
    'Prezzo': 'price'
  };

  const tableFilters = {
    state: urlParams.get('stato'),
    pickUpLocation: urlParams.get('puntoNolo'),
    vehicle: urlParams.get('veicolo'),
    specificDropOffDate: urlParams.get('dataConsegna'),
    search: urlParams.get('search'),
    sortOrder: urlParams.get('ordine'),
    sortField: sortableFieldMap?.[urlParams.get('ordinaPer')] || null,
    _sortField: urlParams.get('ordinaPer'),
  };

  useEffect(() => {
    getRentalLocations();
    getVehicles();
  }, []);

  const getRentalLocations = async () => {
    try {
      const response = await http({ url: `/clients/rentalLocation` });
      setRentalLocations(
        response.rentalLocations.map((rentalLocation) => ({
          value: rentalLocation._id,
          label: `${rentalLocation.name} - ${rentalLocation.city}`,
        }))
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getVehicles = async () => {
    try {
      const response = await http({ url: `/vehicles/vehicle` });
      const orderedVehicles = response.vehicles.sort((a, b) =>
        a.plate.localeCompare(b.plate)
      );
      setVehicles(orderedVehicles);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Page canAccess={[CLIENT_ROLE_ADMIN, CLIENT_ROLE_OPERATOR]}>
      <WhiteBox>
        <TableHeader
          tableName="Movo"
          buttons={[
            {
              to: '/dashboard/movimenti/crea',
              label: 'Nuovo movo',
              svgIco: <PlusOutlineCircle />,
            },
          ]}
          length={rentsCount}
        >
          <div className="flex justify-end gap-2 flex-1 items-center flex-wrap">
            <button className="text-xs opacity-70 hover:opacity-100" onClick={() => openFilters(!filtersOpen)}>
              {filtersOpen ? 'Nascondi filtri' : 'Più filtri'}{' '}
              <FaAngleDown className={`inline mb-1 ${filtersOpen && 'transform rotate-180'}`} />
            </button>
            <FilterTextField
              placeholder="..."
              buttonRight={<FilterSearchButton onClick={() => updateQuery('search', searchQuery)} />}
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </div>
        </TableHeader>

        <Accordion innerClassName="flex flex-wrap justify-between gap-2 pb-6 px-6 !-mt-3" triggerOpen={filtersOpen} hideButtons>
          <div className="mr-auto">
            <TableLengthSelector value={limit} onChange={setLimit} />
          </div>
          <div className="flex flex-wrap gap-1 ml-auto">
            <FilterSelectField
              onChange={(e) => updateQuery('stato', e.target.value)}
              emptyOption={{ label: 'Tutti gli Stati' }}
              defaultValue={rentStates.find((state) => state.value === tableFilters.state)}
              options={rentStates}
              className="w-full md:w-36"
            />
            <FilterSelectField
              onChange={(e) => updateQuery('puntoNolo', e.target.value)}
              emptyOption={{ label: 'Tutti i Punti Nolo' }}
              defaultValue={rentalLocations.find(
                (rental) => rental.value === tableFilters.rentalLocation
              )}
              options={rentalLocations}
              className="w-full md:w-36"
            />
            <FilterSelectField
              onChange={(e) => updateQuery('veicolo', e.target.value)}
              emptyOption={{ label: 'Tutti i veicoli' }}
              defaultValue={vehicles.find((v) => v._id === tableFilters.vehicle)}
              options={vehicles.map((v) => ({ value: v._id, label: v.plate }))}
              className="w-full md:w-36"
            />
            <FilterTextField
              type="date"
              onChange={(e) => updateQuery('dataConsegna', e.target.value)}
              value={tableFilters.specificDropOffDate}
              textLeft={<FilterText text="Consegna il" />}
              className="w-full md:w-64"
            />
          </div>
        </Accordion>

        <RentsTable
          queryProps={tableFilters}
          updateRentsCount={(count) => setRentsCount(count)}
          updateQueryProps={updateQuery}
          sortableCols={Object.keys(sortableFieldMap)}
          tableId="rentsTable"
          addRowNumberSelector
          perPage={limit}
        />
      </WhiteBox>
    </Page>
  );
};

export default Rents;
