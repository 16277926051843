import React, { useContext, useEffect } from 'react';
import FormLabel from '../../UI/FormLabel';
import { TextField } from '../../Form/TextField';
import { SelectField } from '../../Form/SelectField';
import GroupsSelector from '../../UI/GroupsSelector';
import { UserContext } from '../../../store/UserContext';
import { MOVOLAB_ROLE_ADMIN } from '../../../utils/Utils';
import Button from '../../UI/buttons/Button';

const vatPerc = 22;
const addVat = (price) => Math.trunc(price * (100 + vatPerc)) / 100;
const removeVat = (price) => Math.trunc((price / (100 + vatPerc)) * 10000) / 100;

const ExtraDetailsData = ({ form, groups, rentalLocations, isDisabled }) => {
  const { data: currentClient } = useContext(UserContext);

  const amt = form.getValues('cost.amount');
  useEffect(() => {
    form.setValue('cost.amountVat', addVat(amt));
  }, [amt]);

  if (!currentClient) return null;
  if (!currentClient.client && currentClient.role !== MOVOLAB_ROLE_ADMIN) return null;
  let invoicingOptions = [];

  const isMovolabOperator = currentClient.role === MOVOLAB_ROLE_ADMIN;

  if (isMovolabOperator) {
    invoicingOptions = [
      { value: '', label: '-- Seleziona --' },
      { value: 'movolab', label: 'Movolab' },
      { value: 'customer', label: 'Cliente' },
    ];
  } else {
    invoicingOptions = [
      { value: '', label: '-- Seleziona --' },
      { value: 'customer', label: 'Cliente' },
    ];
  }

  const translations = form.watch('translations');

  return (
    <>
      <div>
        <div className="w-[492px] mt-4">
          <FormLabel>Nome</FormLabel>
          <TextField
            form={form}
            name="name"
            placeholder="Nome"
            disabled={isDisabled}
            validation={{
              required: { value: true, message: 'Inserisci Nome' },
            }}
          />
        </div>
        <div className="w-[492px] mt-2">
          <FormLabel>Descrizione</FormLabel>
          <TextField
            form={form}
            name="description"
            type="text"
            placeholder="Descrizione"
            validation={{
              required: { value: true, message: 'Inserisci Descrizione' },
            }}
            disabled={isDisabled}
          />
        </div>

        <div className="w-[492px] mt-2">
          <GroupsSelector
            groups={groups}
            form={form}
            returnParameter={'groups'}
            disabled={isDisabled}
            checkFormIsDirty={() => {}}
          />
        </div>
        <div className="flex mt-2">
          <div className="w-60 mr-3">
            <FormLabel>Tipo</FormLabel>
            <SelectField
              name="type"
              form={form}
              options={[
                { value: 'accessory', label: 'Accessorio' },
                { value: 'service', label: 'Servizio' },
                { value: 'extraCost', label: 'Costo Extra' },
                { value: 'other', label: 'Altro' },
              ]}
              disabled={isDisabled}
              placeholder="Tipo"
              validation={{
                required: { value: true, message: 'Inserisci Tipo' },
              }}
            />
          </div>
          <div className="w-60 mr-3">
            <FormLabel>Applicabilità</FormLabel>
            <SelectField
              name="applicability"
              form={form}
              options={[
                { value: 'manual', label: 'Manuale' },
                { value: 'automatic', label: 'Automatico' },
              ]}
              disabled={isDisabled}
              placeholder="Applicabilità"
            />
          </div>
        </div>
        {form.watch('applicability') === 'manual' && (
          <div className="w-60 mr-3">
            <FormLabel>Disponibile in fase di</FormLabel>
            <SelectField
              name="insertionPhase"
              form={form}
              options={[
                { value: 'pickUp', label: 'Creazione' },
                { value: 'dropOff', label: 'Chiusura' },
                { value: 'both', label: 'Entrambi' },
              ]}
              disabled={isDisabled}
              placeholder="Fase di inserimento"
            />
          </div>
        )}
        {form.watch('applicability') === 'automatic' && (
          <div className="flex flex-wrap rounded-md mt-2">
            <div className="w-60 mr-3">
              <FormLabel>Parametro Regola Automatica</FormLabel>
              <SelectField
                name="automaticRule.parameter"
                form={form}
                options={[
                  { value: 'age', label: 'Età' },
                  { value: 'distance', label: 'Distanza' },
                  { value: 'fuel', label: 'Carburante' },
                  { value: 'rentalLocation', label: 'Punto Nolo' },
                  { value: 'days', label: 'Giorni' },
                ]}
                disabled={isDisabled}
                placeholder="Parametro Regola Automatica"
              />
            </div>
            {form.watch('automaticRule.parameter') === 'fuel' && (
              <div className="w-60 mr-3">
                <FormLabel>Tipo Carburante</FormLabel>
                <SelectField
                  name="automaticRule.fuelCategory"
                  form={form}
                  options={[
                    {
                      value: 'benzina',
                      label: 'Benzina',
                    },
                    {
                      value: 'diesel',
                      label: 'Diesel',
                    },
                    {
                      value: 'ibrida',
                      label: 'Ibrida',
                    },
                    {
                      value: 'elettrico',
                      label: 'Elettrica',
                    },
                    {
                      value: 'gpl',
                      lable: 'GPL',
                    },
                    {
                      value: 'metano',
                      lable: 'Metano',
                    },
                  ]}
                  disabled={isDisabled}
                  placeholder="Parametro Regola Automatica"
                />
              </div>
            )}
            {form.watch('automaticRule.parameter') === 'rentalLocation' && (
              <div className="w-60 mr-3">
                <FormLabel>Punto Nolo</FormLabel>
                <SelectField
                  name="automaticRule.rentalLocation"
                  form={form}
                  options={rentalLocations}
                  disabled={isDisabled}
                  placeholder="Punti Nolo Disponibili"
                />
              </div>
            )}
            {form.watch('automaticRule.parameter') !== 'rentalLocation' && (
              <div className="w-60 mr-3">
                <FormLabel>Check Regola Automatica</FormLabel>
                <SelectField
                  name="automaticRule.check"
                  form={form}
                  options={[
                    { value: 'between', label: 'Valore compreso da... a...' },
                    { value: '<', label: 'Valore minore di...' },
                    { value: '>', label: 'Valore maggiore di...' },
                    { value: '<=', label: 'Valore minore o uguale di...' },
                    { value: '>=', label: 'Valore maggiore o uguale di...' },
                    { value: '=', label: 'Valore uguale a...' },
                  ]}
                  disabled={isDisabled}
                  placeholder="Check Regola Automatica"
                />
              </div>
            )}
            {form.watch('automaticRule.parameter') !== 'rentalLocation' && (
              <div className="w-60 mr-3">
                <FormLabel>Valore Regola Automatica</FormLabel>
                <TextField
                  name="automaticRule.value1"
                  form={form}
                  type="number"
                  validation={{
                    min: { value: 0, message: 'Valore minimo: 0' },
                  }}
                  disabled={isDisabled}
                  placeholder="Valore Regola Automatica"
                />
              </div>
            )}
            {form.watch('automaticRule.check') === 'between' && (
              <div className="w-60 mr-3">
                <FormLabel>Valore Regola Automatica 2</FormLabel>
                <TextField
                  name="automaticRule.value2"
                  form={form}
                  type="number"
                  validation={{
                    min: { value: 0, message: 'Valore minimo: 0' },
                  }}
                  disabled={isDisabled}
                  placeholder="Valore Regola Automatica 2"
                />
              </div>
            )}
          </div>
        )}
        {form.watch('applicability') === 'manual' && (
          <>
            {[0, 1, 2].map((index) => (
              <div
                className="flex flex-wrap rounded-md mt-2 bg-gray-100 p-4 pt-2"
                key={`manual-rule-${index}`}
              >
                <div className="w-60 mr-3">
                  <FormLabel>Parametro Regola Franchigia {index + 1}</FormLabel>
                  <SelectField
                    name={`manualRules[${index}].parameter`}
                    form={form}
                    options={[
                      { value: undefined, label: 'Nessuno' },
                      { value: 'kasko', label: 'Kasko' },
                      { value: 'furto', label: 'Furto' },
                      { value: 'rca', label: 'RCA' },
                    ]}
                    disabled={isDisabled}
                    placeholder="Parametro Regola Franchigia"
                  />
                </div>
                <div className="w-60 mr-3">
                  <FormLabel>Percentuale Riduzione {index + 1}</FormLabel>
                  <TextField
                    name={`manualRules[${index}].reduction`}
                    form={form}
                    type="number-noformat"
                    validation={{
                      min: { value: 0, message: 'Valore minimo: 0' },
                      max: { value: 100, message: 'Valore massimo: 100' },
                    }}
                    disabled={isDisabled}
                    placeholder="Percentuale Riduzione"
                    step=".01"
                    iconRight={<strong className="inline-block mt-1 font-semibold">%</strong>}
                  />
                </div>
              </div>
            ))}
          </>
        )}
        <div className="flex flex-wrap mt-2">
          <TextField
            form={form}
            name="cost.amount"
            type="number-noformat"
            placeholder="Costo Unitario"
            label="Costo Unitario"
            step=".01"
            disabled={isDisabled}
            className="w-full md:w-60 mr-3"
            onChangeFunction={(e) => form.setValue('cost.amountVat', addVat(e.target.value))}
            iconRight={<strong className="inline-block mt-1 font-semibold">&euro;</strong>}
          />
          <TextField
            form={form}
            name="cost.amountVat"
            type="number-noformat"
            placeholder="Costo Unitario"
            label={`Costo Unitario (+ IVA ${vatPerc}%)`}
            step=".01"
            disabled={isDisabled}
            className="w-full md:w-60 mr-3"
            onChangeFunction={(e) => form.setValue('cost.amount', removeVat(e.target.value))}
            iconRight={<strong className="inline-block mt-1 font-semibold">&euro;</strong>}
          />
          <div className="w-60 mr-3">
            <FormLabel>Calcolo Prezzo</FormLabel>
            <SelectField
              name="cost.calculation"
              form={form}
              options={[
                { value: 'fixed', label: 'Fisso' },
                { value: 'daily', label: 'Giornaliero' },
                { value: 'percentage', label: 'Percentuale' },
                { value: 'unit', label: 'Per Unità' },
              ]}
              disabled={isDisabled}
              validation={{
                required: { value: true, message: 'Inserisci Calcolo Prezzo' },
              }}
              placeholder="Calcolo Prezzo"
            />
          </div>
        </div>
        <div className="flex flex-wrap mt-2">
          <div className="w-60 mr-3">
            <FormLabel>Fatturazione</FormLabel>
            {isMovolabOperator ? (
              <SelectField
                name="configuration.invoicingType"
                form={form}
                options={invoicingOptions}
                disabled={isDisabled}
                placeholder="Fatturazione"
              />
            ) : (
              <TextField
                form={form}
                name="invoicingTypeCustomer"
                type="text"
                disabled={true}
                placeholder="Fatturazione"
              />
            )}
          </div>
          <div className="w-60 mr-3">
            <FormLabel>Iva</FormLabel>
            <SelectField
              name="configuration.vatPercentage"
              form={form}
              options={[
                { value: 0, label: '0%' },
                { value: 4, label: '4%' },
                { value: 10, label: '10%' },
                { value: 22, label: '22%' },
              ]}
            />
          </div>
        </div>

        <h3 className="mt-3 font-semibold text-xl">Traduzioni</h3>

        <Button
          btnStyle="inFormStyle"
          className="mb-2"
          onClick={(e) => {
            e.preventDefault();
            form.setValue('translations.' + translations.length, {});
          }}
        >
          Aggiungi traduzione
        </Button>

        {translations?.map((translation, index) => (
          <div className="flex gap-3" key={index}>
            <SelectField
              className="w-2/12"
              name={`translations.${index}.language`}
              form={form}
              placeholder="Seleziona lingua..."
              options={[
                { value: 'it-IT', label: 'Italiano' },
                { value: 'en-EN', label: 'Inglese' },
                { value: 'fr-FR', label: 'Francese' },
                { value: 'es-ES', label: 'Spagnolo' },
                { value: 'de-DE', label: 'Tedesco' },
              ]}
              label="Lingua"
            />
            <TextField
              className="w-3/12"
              form={form}
              name={`translations.${index}.name`}
              type="text"
              label="Nome extra"
              placeholder="Nome extra"
            />
            <TextField
              className="w-7/12"
              form={form}
              name={`translations.${index}.description`}
              type="text"
              label="Descrizione"
              placeholder="Descrizione"
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ExtraDetailsData;
