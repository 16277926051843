import React, { useState } from 'react';

import { http, urlSerialize } from '../../utils/Utils';
import Table from '../UI/Table';
import { Link } from 'react-router-dom';
import { FaLayerGroup, FaLink } from 'react-icons/fa';
import ElementLabel from '../UI/ElementLabel';
import { MdGarage } from 'react-icons/md';
import DisplayDateTime from '../UI/dates/DisplayDateTime';
import { convertPrice } from '../../utils/Prices';
import Button from '../UI/buttons/Button';
import { FaTriangleExclamation } from 'react-icons/fa6';
import ModalVehicleUpdateRentalLocation from './ModalVehicleUpdateRentalLocation';

const reservationStates = [
  { value: 'temp', label: 'Temp', bgColor: 'bg-gray-500' },
  { value: 'draft', label: 'Bozza', bgColor: 'bg-gray-500' },
  { value: 'approval', label: 'In approvazione', bgColor: 'bg-yellow-500' },
  { value: 'aperto', label: 'Aperto', bgColor: 'bg-green-500' },
  { value: 'attivo', label: 'Attivo', bgColor: 'bg-green-600' },
  { value: 'chiuso', label: 'Chiuso', bgColor: 'bg-yellow-600' },
  { value: 'fatturato', label: 'Fatturato', bgColor: 'bg-green-500' },
  { value: 'stornato', label: 'Stornato', bgColor: 'bg-purple-600' },
  { value: 'annullato', label: 'Annullato', bgColor: 'bg-red-400' },
  { value: 'no show', label: 'No Show', bgColor: 'bg-red-600' },
];

const movementTypes = [
  { value: 'NOL', label: 'Noleggio', bgColor: 'bg-blue-500' },
  { value: 'COM', label: 'Comodato', bgColor: 'bg-orange-600' },
  { value: 'MNP', label: 'Movimento non produttivo', bgColor: 'bg-gray-600' },
];

const ElementLabelFormat = ({ value, useStates, className = '' }) => {
  const state = useStates.find((state) => state.value === value);
  return (
    <ElementLabel className={`uppercase ${className}`} bgColor={state?.bgColor}>
      {state?.label || value}
    </ElementLabel>
  );
};

const ReservationsTable = ({ updateReservationsCount, userType, ...props }) => {
  const [showMoveVehicleModal, setShowMoveVehicleModal] = useState(null);

  const fetchReservations = async (skip = 0, limit = 10, queryProps) => {
    const response = await http({
      url: `${
        userType === 'corporate' ? '/corporate' : ''
      }/reservations?skip=${skip}&limit=${limit}&${urlSerialize(queryProps)}`,
    });
    updateReservationsCount(response.count);
    return { resource: response.reservations, count: response.count };
  };

  const generateUrl = (reservation) => {
    let path;
    if (userType === 'corporate') {
      path = '/corporate';
    } else if (userType === 'admin') {
      path = '/admin/clienti';
    } else {
      path = '/dashboard';
    }
    return `${path}/prenotazioni/${reservation._id}`;
  };

  const lateReservation = (reservation) =>
    reservation.state.includes('aperto', 'draft') && new Date(reservation.dropOffDate) < new Date();

  const closeShowModalAndUpdate = () => {
    setShowMoveVehicleModal(null);
  };

  return (
    <>
      <Table
        header={[
          'Codice',
          'Targa',
          'Stato',
          'Conducente',
          'Inizio',
          'Fine',
          'Creazione',
          'Prezzo',
          '',
        ]}
        fetchFunction={fetchReservations}
        emptyTableMessage="Non è stata creata alcuna prenotazione"
        itemsLayout={{
          code: (code, reservation) => (
            <>
              {code.substring(code.length - 8)}
              {reservation.createdFrom === 'widget' ? (
                <FaLayerGroup
                  className="text-red-500 inline-block mb-1 ml-2"
                  title="Creata da widget"
                />
              ) : null}
              {reservation.pickUpLocation._id !== reservation.vehicle.rentalLocation ? (
                <FaTriangleExclamation
                  className="text-yellow-500 inline-block mb-1 ml-2 hover:opacity-80"
                  title="Punto nolo differente"
                  role="button"
                  onClick={() => setShowMoveVehicleModal(reservation)}
                />
              ) : null}
              <br />
              {reservation.movementType !== 'NOL' ? (
                <ElementLabelFormat value={reservation.movementType} useStates={movementTypes} />
              ) : null}
              {reservation?.workflow?.administration?.prepaidReservation ? (
                <ElementLabel className="uppercase" bgColor="bg-red-500">
                  Prepagato
                </ElementLabel>
              ) : null}
            </>
          ),
          vehicle: (vehicle) =>
            userType === 'corporate' ? (
              vehicle?.plate
            ) : (
              <Link className="flex space-x-1" to={`/dashboard/veicoli/flotta/${vehicle?._id}`}>
                <span className="font-semibold">
                  {vehicle?.plate ? vehicle.plate.toUpperCase() : ''}
                </span>
                <span className="text-xs text-blue-600 mt-1">
                  <FaLink />
                </span>
              </Link>
            ),
          state: (state) => (
            <ElementLabelFormat
              value={state === 'draft' && userType === 'corporate' ? 'approval' : state}
              useStates={reservationStates}
            />
          ),
          driver: (driver, reservation) =>
            driver ? (
              <>
                {reservation.driver.name} {reservation.driver.surname}
                <div className="text-xs">{reservation.driver.phone}</div>
              </>
            ) : (
              <>
                {reservation.driverFullName}
                <div className="text-xs">{reservation.driverPhone}</div>
              </>
            ),
          /*movementType: (type) =>
          <ElementLabelFormat value={type} useStates={movementTypes} />,*/
          pickUpDate: (date, reservation) => (
            <>
              <DisplayDateTime date={date} displayType={'flat'} />
              <div className="text-xs">
                {userType === 'corporate' ? (
                  <span className="font-semibold">{reservation?.pickUpLocation?.name}</span>
                ) : (
                  <Link
                    className="flex space-x-1"
                    to={`/settings/puntinolo/${reservation?.pickUpLocation?._id}`}
                  >
                    <span className="font-semibold">{reservation?.pickUpLocation?.name}</span>
                    <span className="text-sm text-blue-600 mt-0.5">
                      <MdGarage />
                    </span>
                  </Link>
                )}
              </div>
            </>
          ),
          dropOffDate: (date, reservation) => (
            <>
              <DisplayDateTime
                date={date}
                displayType={'flat'}
                alert={lateReservation(reservation)}
              />
              <div className="text-xs">
                {userType === 'corporate' ? (
                  <span className="font-semibold">{reservation?.dropOffLocation?.name}</span>
                ) : (
                  <Link
                    className="flex space-x-1"
                    to={`/settings/puntinolo/${reservation?.dropOffLocation?._id}`}
                  >
                    <span className="font-semibold">{reservation?.dropOffLocation?.name}</span>
                    <span className="text-sm text-blue-600 mt-0.5">
                      <MdGarage />
                    </span>
                  </Link>
                )}
              </div>
            </>
          ),
          createdAt: (date) => <DisplayDateTime date={date} />,
          price: (price) =>
            price?.totalAmount ? (
              <>{convertPrice(price?.totalAmount)} </>
            ) : price?.amount ? (
              <>{convertPrice(price?.amount)} </>
            ) : (
              '-'
            ),
          _id: (id, reservation) => (
            <Button to={generateUrl(reservation)} btnStyle="tableItemAction">
              Dettagli &raquo;
            </Button>
          ),
        }}
        rowClassFunction={(reservation) => (lateReservation(reservation) ? 'bg-red-100' : '')}
        {...props}
      />
      {showMoveVehicleModal ? (
        <ModalVehicleUpdateRentalLocation
          showModal={true}
          reservationLocation={showMoveVehicleModal?.pickUpLocation}
          vehicle={showMoveVehicleModal?.vehicle}
          closeShowModalAndUpdate={closeShowModalAndUpdate}
          updateShowModal={() => setShowMoveVehicleModal(null)}
        />
      ) : null}
    </>
  );
};

export default ReservationsTable;
